
import { DASHBOARD_ROOT } from "../../components/Routes/routingConstants";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { Table, Button, Card, CardBody, Alert } from 'reactstrap';
import { useHistory, useParams } from "react-router-dom";
import { useHealthHubContentsForOrganisation } from "../../hooks/useHealthHubContents";
import { useContentOfTheWeekForOrganisation, ContentOfTheWeekType } from "../../hooks/useContentOfTheWeek";
import { useTaskSequenceBucketForOrganisation } from "../../hooks/useTaskSequencesBuckets";
import { HealthHubContent } from "../../interfaces/models/HealthHubContent";
import { TaskSequenceBucket } from "../../interfaces/models/TaskSequenceBucket";
import { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import { getImageSmallUrl } from "../../helper/getImageUrl";
import { ContentOfTheWeek } from "../../interfaces/models/ContentOfTheWeek";
import moment from "moment";
import { DateFormat } from "../../helper/constants";

interface RouteParams {
    id : string;
}

const OrganisationDetailsPage = () => {

    const searchParams = new URLSearchParams(window.location.search);
    const organisationName = searchParams.get("name");
    const { id } = useParams<RouteParams>();

    const breadcrumbsData = [
        { name: 'Content List', path: `${DASHBOARD_ROOT}/content-management/` },
        { name: 'Organisation List', path: `${DASHBOARD_ROOT}/content-management/organisation-list` },
        { name: organisationName, path: `${DASHBOARD_ROOT}/content-management/organisation-list/${id}` },
    ];

    const maxLength = 100;
    const tableMaxHeight = 300;
    const currentDate = new Date();

    const { data : healthHubContentsData, loading : healthHubContentsLoading, error : healthHubContentsError } = useHealthHubContentsForOrganisation(id);
    const { data : challengeData, loading : challengeLoading, error : challengeError } = useContentOfTheWeekForOrganisation(ContentOfTheWeekType.CHALLENGE, id);
    const { data : bucketData, loading : bucketLoading, error : bucketError } = useTaskSequenceBucketForOrganisation(id);

    const [healthHubContents, setHealthHubContents] = useState<HealthHubContent[]>([]);
    const [challenges, setChallenges] = useState<ContentOfTheWeek[]>([]);
    const [bucket, setBucket] = useState<TaskSequenceBucket>(null);

    const history = useHistory();

    const HandleChallengeRedirect = (item : ContentOfTheWeek) => {
        history.push(`${DASHBOARD_ROOT}/content-management/challenge-of-the-week?selected=${item._id}`);
    }

    const HandleHealthHubRedirect = (item : HealthHubContent) => {
        history.push(`${DASHBOARD_ROOT}/content-management/health-hub?selected=${item._id}`);
    }

    const HandleBucketRedirect = (item : TaskSequenceBucket) => {
        history.push(`${DASHBOARD_ROOT}/content-management/day-sequences/buckets?selected=${item._id}`);
    }

    useEffect(() => {
        if(healthHubContentsData) {
            setHealthHubContents(healthHubContentsData.health_hub_contents_for_org);
        }
    },[healthHubContentsData]);

    useEffect(() => {
        if(challengeData) {
            setChallenges(challengeData.content_of_the_weeks_for_org);
        }
    },[challengeData]);

    useEffect(() => {
        if(bucketData) {
            setBucket(bucketData.task_sequence_bucket_for_org);
        }
    },[bucketData]);


    return (
        <>
            <Breadcrumbs 
                crumbs={breadcrumbsData}
            />
            <h3 className="pt-2">Challenge of the Week Contents</h3>
            <Card>
                <CardBody>
                    {
                        challengeError &&
                        <Alert color="danger">
                            Error on Getting Challenges
                        </Alert>
                    }
                    <div className="table-responsive" style={{maxHeight : tableMaxHeight}}>
                        <Table striped bordered>
                             <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Title & Description</th>
                                    <th>Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                challengeLoading && 
                                <tr>
                                    <td colSpan={4}>
                                        <Loading />
                                    </td>
                                </tr>
                            }
                            {
                                challenges.length === 0 && !challengeLoading &&
                                <tr>
                                    <td colSpan={4} className="text-center">No Challenges Found</td>
                                </tr>
                            }
                            {
                                challenges.map((item, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>
                                                <img
                                                    style={{
                                                        borderRadius: "0.375rem",
                                                        marginBottom: "1rem",
                                                        height : "100px"
                                                    }}
                                                    src={getImageSmallUrl(item.image_id)}
                                                    alt={item.title}
                                                />
                                            </td>
                                            <td>
                                                <div className="font-weight-bold">{item.title}</div>
                                                <div className="table-description-details">
                                                    { item.description.length > maxLength ? 
                                                    item.description.slice(0, maxLength) + " ..."
                                                    : item.description }
                                                </div>
                                            </td>
                                            <td>
                                                {
                                                    new Date(item.end_date) < currentDate 
                                                    ? <span title="Date already passed" className=" text-danger"><i className="far fa-calendar-times fa-lg"></i> {moment(item.start_date).format(DateFormat)} to {moment(item.end_date).format(DateFormat)}</span>
                                                    : new Date(item.start_date) <= currentDate && new Date(item.end_date) >= currentDate 
                                                    ? <span title="Currently Showing for the week" className=" text-success"><i className="far fa-calendar-check fa-lg"></i> {moment(item.start_date).format(DateFormat)} to {moment(item.end_date).format(DateFormat)}</span>
                                                    : <span title="Set for the Future Dates" className=" text-primary"><i className="fas fa-hourglass-half"></i> {moment(item.start_date).format(DateFormat)} to {moment(item.end_date).format(DateFormat)}</span>
                                                } 
                                            </td>
                                            <td>
                                                <Button color="primary" size='sm' onClick={()=> { HandleChallengeRedirect(item) }} >View</Button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>
            <h3 className="pt-2">Health Hub Contents</h3>
            <Card>
                <CardBody>
                    {
                        healthHubContentsError &&
                        <Alert color="danger">
                            Error on Getting Health Hub Contents
                        </Alert>
                    }
                    <div className="table-responsive" style={{maxHeight : tableMaxHeight}}>
                        <Table striped bordered>
                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Title & Description</th>
                                    <th>Link</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                healthHubContentsLoading && 
                                <tr>
                                    <td colSpan={4}>
                                        <Loading />
                                    </td>
                                </tr>
                            }
                            {
                                healthHubContents.length === 0 && !healthHubContentsLoading &&
                                <tr>
                                    <td colSpan={4} className="text-center">No Links Found</td>
                                </tr>
                            }
                            {
                                healthHubContents.map((link, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>
                                                <img
                                                    style={{
                                                        borderRadius: "0.375rem",
                                                        marginBottom: "1rem",
                                                        height : "100px"
                                                    }}
                                                    src={getImageSmallUrl(link.image_id)}
                                                    alt={link.alt}
                                                />
                                            </td>
                                            <td>
                                                <div className="font-weight-bold">{link.name}</div>
                                                <div className="table-description-details">
                                                    { link.description.length > maxLength ? 
                                                    link.description.slice(0, maxLength) + " ..."
                                                    : link.description }
                                                </div>
                                            </td>
                                            <td >
                                                <div className="table-description-details" >
                                                    <a href={link.link} target="_blank" rel="noreferrer">
                                                        {link.link}
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <Button color="primary" size='sm' onClick={()=> { HandleHealthHubRedirect(link) }} >View</Button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>
            <h3 className="pt-2">Sequence Bucket</h3>
            <Card>
                <CardBody>
                    {
                        bucketError &&
                        <Alert color="danger">
                            Error on Getting Bucket for Organisation
                        </Alert>
                    }
                    <div className="table-responsive" style={{maxHeight : tableMaxHeight}}>
                        <Table striped bordered>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th>Type</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    bucketLoading && 
                                    <tr>
                                        <td colSpan={4}>
                                            <Loading />
                                        </td>
                                    </tr>
                                }
                                {
                                    !bucket && !bucketLoading &&
                                    <tr>
                                        <td colSpan={4} className="text-center">No Custom Bucket Found</td>
                                    </tr>
                                }
                                {
                                    bucket && 
                                    <tr >
                                        <td>{bucket.name}</td>
                                        <td>
                                            <div className="table-description-details">
                                                    { bucket.description.length > maxLength ? 
                                                    bucket.description.slice(0, maxLength) + " ..."
                                                    : bucket.description }
                                                </div>
                                        </td>
                                        <td>{bucket.type}-Day Sequence</td>
                                        <td>
                                            <Button color="primary" size='sm' onClick={()=> { HandleBucketRedirect(bucket) }}>View</Button>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>
        </>
    )

}
export { OrganisationDetailsPage as OrganisationDetails };