import { Table, Button,  Modal, ModalHeader, ModalBody, ModalFooter, Alert, FormGroup, Label, Col, Input, Row, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { Field, Form, Formik, FormikProps } from "formik";
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import {
    DASHBOARD_ROOT,
} from "../../components/Routes/routingConstants";
import { useState , useEffect, useRef } from 'react';
import Loading from '../../components/Loading';
import TextFormInput from "../../components/Shared/Forms/TextFormInput";
import { useTaskSequencesNameIdOnly } from "../../hooks/useTaskSequencesForManagement"
import { useTaskSequenceBucketsWithOrganisation } from "../../hooks/useTaskSequencesBuckets" 
import { addTaskSequenceBucket , updateTaskSequenceBucket , activateTaskSequenceBucket, removeTaskSequenceBucket, applyBucketToOrgSequenceBucket } from "../../graphql/content-management/addTaskSequenceBuckets"
import toast from 'react-hot-toast';
import { useMutation } from "@apollo/client"
import PromptDialogs from "../../components/PromptDialog";
import AddTaskSequenceBucketValidator from "../../validators/AddTaskSequenceBucketValidator"
import FormError from '../../components/Shared/Forms/FormError';
import { useOrganisation } from '../../hooks/useOrganisation';
import  { TaskSequenceBucketWithOrganisation } from "../../interfaces/models/TaskSequenceBucket"
import { Organisation } from '../../interfaces/models/Organisation';
import OrganisationSelectorModal from "../../components/ContentManagement/OrganisationSelectorModal"

//#region Interfaces 
interface TaskSequence { 
    name : string;
    _id: string;
}

interface DataFormik {
    _id : string;
    name : string;
    description : string;
    sequenceList : string[];
    type : number;
}

interface ModalLabels { 
    name : string;
    buttonName : string;
    action : string;
}

//#endregion Interfaces

const SequenceBucketEditorPage = () => {
//#region Initializations
    const breadcrumbsData = [
        { name: 'Content List', path: `${DASHBOARD_ROOT}/content-management/` },
        { name: 'Edit Sequence Buckets', path: `${DASHBOARD_ROOT}/content-management/day-sequences/buckets` },
    ];

    const searchParams = new URLSearchParams(window.location.search);
    const isRecentlyApplied = searchParams.get("hasEdited");
    const editedName = searchParams.get("editedBucketName");
    const editedBucketId = searchParams.get("editedBucketId");
    const preOpenedItem = searchParams.get("selected");

    const [taskSequences, setTaskSequences] = useState<TaskSequence[]>([]);
    const [taskSequencesFiltered, setTaskSequencesFiltered] = useState<TaskSequence[]>([]);
    const [bucket, setBucket] = useState<TaskSequenceBucketWithOrganisation[]>([]);
    const [bucketFiltered, setBucketFiltered] = useState<TaskSequenceBucketWithOrganisation[]>([]);

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false); 
    const [modalLabels, setModalLabels] = useState<ModalLabels>({
        name : "",
        buttonName : "",
        action : ""
    });

    const formikRef = useRef<FormikProps<DataFormik>>(null);
    const [isOnProcess, setIsOnProcess] = useState<boolean>(false);
    const [initialValue, setInitialValue] = useState<DataFormik>(null);
    const [toBeUpdated, setToBeUpdated] = useState<TaskSequenceBucketWithOrganisation>(null);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [toBeDeletedData, setToBeDeletedData] = useState<TaskSequenceBucketWithOrganisation>(null);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const [isActivateModalOpen, setIsActivateModalOpen] = useState<boolean>(false);
    const [toBeActivatedData, setToBeActivatedData] = useState<TaskSequenceBucketWithOrganisation>(null);
    const [isActivating, setIsActivating] = useState<boolean>(false);

    const [isApplyModalOpen, setIsApplyModalOpen] = useState<boolean>(false);
    const [toBeAppliedData, setToBeAppliedData] = useState<TaskSequenceBucketWithOrganisation>(null);
    const [isApplying, setIsApplying] = useState<boolean>(false);
    
    const [isSetupModalOpen, setIsSetupModalOpen] = useState<boolean>(false); 
    const [selectedOrganisations, setSelectedOrganisations] = useState<string[]>([]);
    const [tobeSetuped, setTobeSetuped] = useState<TaskSequenceBucketWithOrganisation>();
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const [organisations, setOrganisations] = useState<Organisation[]>([]);
    const [search, setSearch] = useState<string>("");
    const [searchOrg, setSearchOrg] = useState<string>("");
    const [isSearching, setIsSearching] =  useState<boolean>(false);
    let timer = useRef<number | null> (null);

    const [highlightedItem, setHighlightedItem] = useState<string | null>(null);
    const targetRowRef = useRef(null);
    
    const { data : sequenceData, loading : sequenceLoading , error : sequenceError } = useTaskSequencesNameIdOnly();
    const { data, loading, error } = useTaskSequenceBucketsWithOrganisation();
    const { data : organisationData, loading : organisationLoading} = useOrganisation();

    const [addTaskSequenceBucketHook] = useMutation(addTaskSequenceBucket,{
        onCompleted : () =>{
            toast.success("Bucket was successfully Added");
        },
        onError : () =>{
            toast.error("Something went wrong whilst adding Bucket details  . Please try again or if issue persists, please contact us");
        }
    });

    const [updateTaskSequenceBucketHook] = useMutation(updateTaskSequenceBucket,{
        onCompleted : () =>{
            toast.success("Bucket was successfully Updated");
        },
        onError : () =>{
            toast.error("Something went wrong whilst updating Bucket details  . Please try again or if issue persists, please contact us");
        }
    });

    const [removeTaskSequenceBucketHook] = useMutation(removeTaskSequenceBucket,{
        onCompleted : () =>{
            toast.success("Bucket was successfully Deleted");
        },
        onError : () =>{
            toast.error("Something went wrong whilst Deleting Bucket details  . Please try again or if issue persists, please contact us");
        }
    });

    const [activateTaskSequenceBucketHook] = useMutation(activateTaskSequenceBucket,{
        onCompleted : () =>{
            toast.success("Bucket was successfully Activated");
        },
        onError : () =>{
            toast.error("Something went wrong whilst Activating Bucket details  . Please try again or if issue persists, please contact us");
        }
    });

    const [applyBucketToOrgSequenceBucketHook] = useMutation(applyBucketToOrgSequenceBucket,{
        onCompleted : () =>{
            toast.success("Bucket was successfully Applied to Organisations");
        },
        onError : () =>{
            toast.error("Something went wrong whilst Applying Bucket details  . Please try again or if issue persists, please contact us");
        }
    });
    
    useEffect(() =>{
        if(sequenceData){
            setTaskSequences(sequenceData.task_sequences_sort_by_name);
            setTaskSequencesFiltered(sequenceData.task_sequences_sort_by_name);
        }
    },[sequenceData])

    useEffect(() => {
        if(data) {
            setBucket(data.task_sequence_buckets);
            setBucketFiltered(data.task_sequence_buckets);
        }
    },[data]);

    useEffect(() =>{
        if(organisationData) {
            setOrganisations(organisationData.organisations);
        }
    },[organisationData]);

    const Reloader = () => {
        setTimeout(function (){
            window.location.reload();
        },1500);
        
    }

    const ToggleModal = () =>{
        setIsModalOpen(!isModalOpen);
    }

    const TriggerSubmit = () => {
        formikRef.current?.submitForm();
    }

    useEffect(() => {
        if(bucket) {
            if(preOpenedItem) {
                const getItem = bucket.find((u) => u._id === preOpenedItem);
                if(getItem) {
                    HandleOpenEdit(getItem);
                    setHighlightedItem(preOpenedItem);
                }
            }
        }
    },[bucket,preOpenedItem]);

    useEffect(() => {

        if(highlightedItem && targetRowRef) {
            targetRowRef.current.scrollIntoView({
                behavior: 'smooth', // Smooth scrolling
                block: 'nearest',   // Align the row to the nearest edge
            });
        }

    },[highlightedItem]);
//#endregion Initializations
//#region Add and Edit Functions
    const HandleOpenAdd = () => {
        setInitialValue({
            _id : "",
            name : "",
            description : "",
            sequenceList : [],
            type : 0,
        })
        setModalLabels({
            name : "Add Bucket",
            buttonName : "Add",
            action : "add"
        })

        setIsModalOpen(true);
        
    }

    const HandleOpenEdit = (item : TaskSequenceBucketWithOrganisation) => {
        setInitialValue({
            _id : item._id,
            name : item.name,
            sequenceList : item.task_sequences_ids,
            description : item.description,
            type : item.type,
        })
        setModalLabels({
            name : "Edit Bucket",
            buttonName : "Edit",
            action : "edit"
        })
        setToBeUpdated(item);
        setIsModalOpen(true);
    }

    const HandleSubmit = async  (formData : DataFormik) =>{
        if(isOnProcess) return;

        setIsOnProcess(true);
        
        if(modalLabels.action === "add") {
            const isSuccess = await addTaskSequenceBucketHook({
                variables : {
                    record : {
                        name : formData.name,
                        task_sequences_ids : formData.sequenceList,
                        description : formData.description,
                        type : Number(formData.type),
                        status : false,
                    }
                }
            }).then((res) => res.errors ? false : true).catch(()=>false);

            if(!isSuccess) {
                setIsOnProcess(false);
                return;
            }

            Reloader();
        }
        else if(modalLabels.action === "edit") {

            // compare previous and current task sequence ids
            let isChanged = false;
            for( let x = 0; x < toBeUpdated.task_sequences_ids.length; x++) {
                if(!formData.sequenceList.includes(toBeUpdated.task_sequences_ids[x])) {
                    isChanged = true;
                    break;
                }
            }

            const isSuccess = await updateTaskSequenceBucketHook({
                variables : {
                    _id : initialValue._id,
                    record : {
                        name : formData.name,
                        task_sequences_ids : formData.sequenceList,
                        description : formData.description,
                        type : Number(formData.type),
                        applied_to_organisations : !isChanged
                    }
                }
            }).then((res) => res.errors ? false : true).catch(()=>false);

            if(!isSuccess) {
                setIsOnProcess(false);
                return;
            }

            

            if(isChanged) {
                setTimeout(function (){
                    window.location.href = `${DASHBOARD_ROOT}/content-management/day-sequences/buckets?hasEdited=true&editedBucketName=${toBeUpdated.name}`;
                },1500);
            }
            else {
                Reloader();
            }
        }
    }

    const HandleFilterDaySequence = (value : string) => {
        const filtered = taskSequences.filter( u => u.name.toLowerCase().includes(value.toLowerCase()));
        setTaskSequencesFiltered(filtered);
    }
//#endregion Add and Edit Functions
//#region Delete Functions
    const HandleOpenDelete = (item : TaskSequenceBucketWithOrganisation) => {
        setToBeDeletedData(item);
        setIsDeleteModalOpen(true);
    }

    const HandleDelete = async () =>{
        if(isDeleting) return;

        setIsDeleting(true);

        const isSuccess = await removeTaskSequenceBucketHook({
            variables : {
                _id : toBeDeletedData._id,
            }
        }).then((res) => res.errors ? false : true).catch(()=>false);

        if(!isSuccess) {
            setIsDeleting(false);
            return;
        }

        Reloader(); 
    }

//#endregion Delete Functions
//#region Activate Functions
    const HandleOpenActivate = (item : TaskSequenceBucketWithOrganisation) => {
        setToBeActivatedData(item);
        setIsActivateModalOpen(true);
    }

    const HandleActivate = async () =>{
        if(isActivating) return;

        setIsActivating(true);

        const isSuccess = await activateTaskSequenceBucketHook({
            variables : {
                bucketId : toBeActivatedData._id,
            }
        }).then((res) => res.errors ? false : true).catch(()=>false);

        if(!isSuccess) {
            setIsActivating(false);
            return;
        }

        Reloader();
    }
 
//#endregion Activate Functions

//#region Setup Organisation
const HandleOpenSetup = (item : TaskSequenceBucketWithOrganisation) => {
    ToggleModalSetup();
    setTobeSetuped(item);
    setSelectedOrganisations(item.using_organisation_ids);
}

const ToggleModalSetup = () => {
    setIsSetupModalOpen(!isSetupModalOpen);
}

const HandleSaveSelectedOrganisation = async (selected : string[]) => {

    // if( tobeSetuped.using_organisation_ids.length !== 0) {
    //     let isChanged = false;
    //     for( let x = 0; x < tobeSetuped.using_organisation_ids.length; x++) {
    //         if(!selected.includes(tobeSetuped.using_organisation_ids[x])) {
    //             isChanged = true;
    //         }
    //     }

    //     if(!isChanged) {
    //         toast.error("No Changes has been Made");
    //         return;
    //     }
    // }

    let isOrganisationIsUsed = false;
    let organisationAlreadyUsedId = "";
    for( let x = 0; x < selected.length; x++) {
        for(let i = 0; i < bucket.length; i++) {
            let item = bucket[i];
            if(item.using_organisation_ids.includes(selected[x]) && item._id !== tobeSetuped._id) {
                isOrganisationIsUsed = true;
                organisationAlreadyUsedId = selected[x];
                break;
            }
        }
    }

    if(isOrganisationIsUsed) {
        const getOrganisation = organisations.find((item) => item._id === organisationAlreadyUsedId);
        toast.error(`Organisation "${getOrganisation.name}" is already used in another Bucket`);
        return;
    }

    setIsSaving(true);
    const isUpdated = await updateTaskSequenceBucketHook({
        variables : {
            _id : tobeSetuped._id,
            record : {
                using_organisation_ids : selected,
                applied_to_organisations :  false
            }
        },
    }).then((res) => res.errors ? false : true).catch(()=>false);

    
    if(!isUpdated) {
        setIsSaving(true);
        return;
    }

    ToggleModalSetup();
    setTimeout(function (){
        window.location.href = `${DASHBOARD_ROOT}/content-management/day-sequences/buckets?hasEdited=true&editedBucketName=${tobeSetuped.name}&editedBucketId=${tobeSetuped._id}`;
    },1500);
}
const HandleSearch = (value : string) => {
    filterChallenge(value, searchOrg);
}

const HandleOrganisationChange = (value : string) => {
    filterChallenge(search, value);
    setSearchOrg(value);
}

const HandleClear = () => {
    setSearchOrg("");
    setSearch("");
    filterChallenge("","");
}

const filterChallenge = (searchText : string, searchOrganisation : string) => {

    searchText = searchText?.trim().toLowerCase();
    setIsSearching(true);
    if(timer?.current) {
        clearTimeout(timer.current);
        timer.current = null;
    }

    timer.current = window.setTimeout(() => {
        const filtered = bucket.filter( u => 
            (!searchText || u.name.toLowerCase().includes(searchText) || u.description.toLowerCase().includes(searchText)) &&
            (!searchOrganisation || u.using_organisation_ids?.includes(searchOrganisation))
        );
        setBucketFiltered(filtered);
        setIsSearching(false);
    },1500);

}
//#endregion Setup Organisation

//#region Apply To Organisation 
const  HandleOpenApply = (item : TaskSequenceBucketWithOrganisation) => {
    setToBeAppliedData(item);
    setIsApplyModalOpen(true);

}

const HandleApply =  async () => {
    if(isApplying) return;

    setIsApplying(true);

    const isSuccess = await applyBucketToOrgSequenceBucketHook({
        variables : {
            bucketId : toBeAppliedData._id,
        }
    }).then((res) => res.errors ? false : true).catch(()=>false);

    if(!isSuccess) {
        setIsApplying(false);
        return;
    }

    setTimeout(function (){
        window.location.href = `${DASHBOARD_ROOT}/content-management/day-sequences/buckets`;
    },1500);
}
//#endregion Apply To Organisation 
    return (
        <>
            <Breadcrumbs 
                crumbs={breadcrumbsData}
            />
            {
                error &&
                <Alert color="danger">
                    Oops, we’re having trouble getting the Bucket List. Please give it a minute, and if it doesn’t resolve, let us know.
                </Alert>
            }
            {
                isRecentlyApplied &&
                <Alert color="info">
                    Please don't forget to click "<b>Apply</b>" on Task Sequence Bucket named "<b className="text-capitalize">{editedName}</b>"" to apply the changes to the organisations.
                </Alert>
            }
            <Row className="pb-2">
                <Col className="d-flex">
                    <label className="mt-2 mr-2">Search: </label>
                    <Input className="w-100" type="text" value={search} onChange={(e) => { setSearch(e.currentTarget.value); }} onKeyUp={(e) => { HandleSearch(e.currentTarget.value); }} placeholder="Search name and description here" />
                </Col>
                <Col className="d-flex">
                    <label className="mt-2 mr-2">Organisation: </label>
                    <Input className="w-100" type="select" disabled={organisationLoading} onChange={(e) => ( HandleOrganisationChange(e.currentTarget.value) )} value={searchOrg}>
                        <option value="">All Organisation</option>
                        {
                            organisations.map((item, index) => 
                                <option value={item._id} key={index}>{item.name}</option>
                            )
                        }
                    </Input>
                    <Button color="secondary ml-3" onClick={HandleClear}>Clear</Button>
                    <Button color="primary" onClick={HandleOpenAdd}>Add</Button>
                </Col>
            </Row>
            <div className="table-responsive table-max-height">
                <Table striped bordered>
                    <thead>
                        <tr>
                            <th>Name & Description</th>
                            <th>Organisation</th>
                            <th>Type</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (!loading && !isSearching && bucketFiltered.length === 0) &&
                            <tr>
                                <td colSpan={5} className="text-center">
                                    No Data Found
                                </td>
                            </tr>
                        }
                        {
                            (loading || isSearching) && 
                            <tr>
                                <td colSpan={5}>
                                    <Loading />
                                </td>
                            </tr>
                        }
                        {
                            !isSearching && bucketFiltered.map((item, index ) => {
                                return (
                                    <tr key={index}
                                    className={ item._id === highlightedItem ? "table-bg-highlighted" : "" }
                                    ref={ item._id === highlightedItem ? targetRowRef : null } 
                                    >
                                        <td >
                                            <div className="font-weight-bold">{item.name}</div>
                                            <div style={{ width : 300, maxWidth : 300, 
                                                wordWrap :"break-word", overflowWrap :"break-word", 
                                                whiteSpace: "normal" }}>
                                                {item.description}
                                            </div>
                                        </td>
                                        <td >
                                            {
                                                item.status ? 
                                                <span className="badge bg-success text-white">Default</span>
                                                :
                                                <div style={{ width : 250, maxWidth : 250, 
                                                    wordWrap :"break-word", overflowWrap :"break-word", 
                                                    whiteSpace: "normal" }}>
                                                    {
                                                        item.applied_to_organisations &&
                                                        <span className="badge bg-warning text-white">Applied</span>
                                                    }
                                                    <div>
                                                        {item.using_organisations.map(u => u.name)?.join(",")}  
                                                    </div>
                                                    
                                                </div>
                                            }
                                            
                                        </td>
                                        <td>{item.type}-Day Sequence</td>
                                        <td>
                                            {
                                                !item.status && 
                                                <>
                                                    {/* {
                                                        item.using_organisations.length === 0 &&
                                                        <Button color="success" size='sm'onClick={()=>{ HandleOpenActivate(item) }} >Default</Button>
                                                    }
                                                    <Button color="primary" size='sm'onClick={()=>{ HandleOpenEdit(item) }} >Edit</Button>
                                                    
                                                    <Button color="primary" size='sm'onClick={()=>{ HandleOpenSetup(item) }} >Organisations</Button>
                                                    <Button color="danger" size='sm' onClick={()=>{ HandleOpenDelete(item)}} >Delete</Button> */}
                                                    <UncontrolledDropdown
                                                        className="me-2"
                                                        direction="down"
                                                        size='sm'
                                                    >
                                                        <DropdownToggle
                                                            caret
                                                            color="primary"
                                                        >
                                                            Actions
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            {
                                                                (item.using_organisations.length === 0 && editedBucketId !== item._id) &&
                                                                <DropdownItem onClick={()=>{ HandleOpenActivate(item) }}>
                                                                    <small >Make Default</small>
                                                                </DropdownItem>
                                                            }
                                                            {
                                                                ((!item.applied_to_organisations && item.using_organisation_ids.length !== 0 ) || editedBucketId === item._id) &&
                                                                <DropdownItem onClick={()=>{ HandleOpenApply(item) }}>
                                                                    <small >Apply to Organisations</small>
                                                                </DropdownItem>
                                                            }
                                                            <DropdownItem onClick={()=>{ HandleOpenEdit(item) }}>
                                                                <small >Edit</small>
                                                            </DropdownItem>
                                                            <DropdownItem onClick={()=>{ HandleOpenSetup(item) }} >
                                                                <small>Organisations</small>
                                                            </DropdownItem>
                                                            <DropdownItem onClick={()=>{ HandleOpenDelete(item) }} >
                                                                <small className="text-danger">Delete</small>
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </>
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        
                    </tbody>
                </Table>
            </div>
            <Modal
                id="sequenceBucket-edit-modal"
                className="modal-xl"
                backdrop={true}
                isOpen={isModalOpen}>
                <ModalHeader toggle={ToggleModal} className="pb-0">
                    <p className="text-lg">{modalLabels.name}</p>
                </ModalHeader>
                <ModalBody>
                    <Formik
                        innerRef={formikRef}
                        initialValues={initialValue}
                        validationSchema={AddTaskSequenceBucketValidator}
                        onSubmit={async (value : DataFormik) =>{
                            await HandleSubmit(value);
                        }}
                    >
                        {
                            ({errors, touched, values}) => (
                                <Form>
                                    <Row>
                                        <Col md={6}>
                                            <TextFormInput
                                                name={"name"}
                                                placeholder="Enter Name"
                                                label="Name"
                                                required={true}
                                            />
                                            <div className="form-group mb-0">
                                                <label>Description *</label>
                                                <Field
                                                    className="form-control"
                                                    name="description"
                                                    placeholder="Enter Description"
                                                    as="textarea"
                                                />
                                                <FormError>{touched.description && (errors.description)}</FormError>
                                            </div>
                                            <FormGroup className="mb-0">
                                                <Label>Type</Label>
                                                <Field as="select" name="type" className="form-control">
                                                    <option value="0" label="Select Type" />
                                                    <option value="5" label="5-Day Sequence" />
                                                    <option value="10" label="10-Day Sequence" />
                                                    <option value="15" label="15-Day Sequence" />
                                                    <option value="20" label="20-Day Sequence" />
                                                </Field>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            {
                                                (!sequenceLoading && sequenceError) &&
                                                <Alert color="danger" className="mt-2">
                                                    Oops, we’re having trouble getting the Task Sequences. Please give it a minute, and if it doesn’t resolve, let us know.
                                                </Alert>
                                            } 
                                            {   
                                                (touched.sequenceList && errors.sequenceList) &&
                                                <Alert color="warning" className="mt-2">
                                                    { errors.sequenceList } - Select {values.type} Items
                                                </Alert>
                                            }
                                            <div className="d-flex pt-4 pb-2">
                                                <label className="mt-2 mr-2">Search: </label>
                                                <Input className="w-100" type="text" onKeyUp={(e) => { HandleFilterDaySequence(e.currentTarget.value); }} placeholder="Search Sequence here" />
                                            </div>
                                            <div className="table-responsive mt-2" style={{maxHeight:"400px"}}>
                                                
                                                <Table>
                                                    <thead className="bg-white sticky-top">
                                                        <tr>
                                                            <th></th>
                                                            <th >Name</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            (!sequenceLoading && taskSequencesFiltered.length === 0) &&
                                                            <tr>
                                                                <td colSpan={3} className="text-center">
                                                                    No Data Found
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            sequenceLoading &&
                                                            <tr>
                                                                <td colSpan={3} className="text-center">
                                                                    <Loading />
                                                                </td>
                                                            </tr> 
                                                        }
                                                        {
                                                            taskSequencesFiltered.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <Field className="form-control" type="checkbox" name="sequenceList" value={item._id} />
                                                                        </td>
                                                                        <td valign="bottom" >
                                                                            <div style={{ width : 150, maxWidth : 150, 
                                                                                wordWrap :"break-word", overflowWrap :"break-word", 
                                                                                whiteSpace: "normal" }}>
                                                                                {item.name}
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <a className="btn btn-secondary btn-sm" href={`${DASHBOARD_ROOT}/content-management/day-sequences/${item._id}?name=${item.name}`} target="_blank" rel="noreferrer">
                                                                                View Sequence
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Col>
                                    </Row>
                                    
                                    
                                </Form>
                            )
                        }
                    </Formik>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" disabled={isOnProcess} onClick={ToggleModal} >Close </Button>
                    <Button color="primary" disabled={isOnProcess} onClick={TriggerSubmit} >{modalLabels.buttonName}</Button>
                </ModalFooter>
            </Modal>

            <PromptDialogs
                isOpen={isDeleteModalOpen}
                onCancel={() => { setIsDeleteModalOpen(false); }}
                onConfirm={HandleDelete}
                title="Delete Bucket"
                promptMessage="Are you sure you want to delete this Bucket?"
                confirmBtnTxt="Delete"
                isOnProcess={isDeleting}
            />

            <PromptDialogs
                isOpen={isActivateModalOpen}
                onCancel={() => { setIsActivateModalOpen(false); }}
                onConfirm={HandleActivate}
                title="Mark Bucket as Default"
                promptMessage="Are you sure you want to make this Bucket a default? It will be applied instantly"
                confirmBtnTxt="Mark"
                isOnProcess={isActivating}
            />

            <PromptDialogs
                isOpen={isApplyModalOpen}
                onCancel={() => { setIsApplyModalOpen(false); }}
                onConfirm={HandleApply}
                title="Apply to Organisations"
                promptMessage="Are you sure you want to apply this to organisations under this bucket? It will be applied instantly"
                confirmBtnTxt="Apply"
                isOnProcess={isApplying}
            />

            <OrganisationSelectorModal
                toggleModal={ToggleModalSetup}
                modalOpen={isSetupModalOpen}
                handleSave={HandleSaveSelectedOrganisation}
                organisations={organisations}
                isButtonDisabled={isSaving}
                selectedOrgs={selectedOrganisations}
            />
        </>
    )

}

export { SequenceBucketEditorPage as SequenceBucketEditor };