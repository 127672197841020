import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { Table, Button,  Modal, ModalHeader, ModalBody, ModalFooter, Alert, Row, Col, Input } from 'reactstrap';
import {
    DASHBOARD_ROOT,
} from "../../components/Routes/routingConstants";
import { useState , useEffect, useRef } from 'react';
import { Field, Form, Formik, FormikProps } from "formik";
import EditHealthHubValidator from "../../validators/EditHealthHubValidator"
import TextFormInput from "../../components/Shared/Forms/TextFormInput";
import FormError from "../../components/Shared/Forms/FormError";
import { executeDeleteImage } from "../../graphql/queries/getImageUploadURL";
import useImageUpload from "../../hooks/useImageUpload";
import { useMutation } from "@apollo/client";
import Loading from '../../components/Loading';
import PromptDialogs from "../../components/PromptDialog";
import { HealthHubContentWithOrganisation } from "../../interfaces/models/HealthHubContent"
import { useHealthHubContentsWithOrganisation } from "../../hooks/useHealthHubContents";
import toast from "react-hot-toast";
import { addHealthHubContent, editHealthHubContent, removeHealthHubContent } from "../../graphql/content-management/addHealthHubContents";
import { getImageSmallUrl } from "../../helper/getImageUrl";
import ImageUploadInput from '../../components/ContentManagement/ImageUploadInput';
import OrganisationSelectorModal from "../../components/ContentManagement/OrganisationSelectorModal"
import { Organisation } from '../../interfaces/models/Organisation';
import { useOrganisation } from '../../hooks/useOrganisation';

interface HealthHubLinks {
    id : string;
    image : string;
    image_id : string;
    name : string;
    description : string;
    url : string;
    alt : string;
}


const HealthHubEditorPage = () => {

//#region Initialization
    const breadcrumbsData = [
        { name: 'Content List', path: `${DASHBOARD_ROOT}/content-management/` },
        { name: 'Edit Health Hub', path: `${DASHBOARD_ROOT}/content-management/health-hub` },
    ];

    const maxLength = 100;

    const searchParams = new URLSearchParams(window.location.search);
    const preOpenedItem = searchParams.get("selected");

    const [links, setLinks] = useState<HealthHubContentWithOrganisation[]>([]);
    const [linksFiltered, setLinksFiltered] = useState<HealthHubContentWithOrganisation[]>([]);
    
    const formikRef = useRef<FormikProps<HealthHubLinks>>(null);
    const [initialValue, setInitialValue] = useState<HealthHubLinks>(null); 

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false); 
    const [modalName, setModalName] = useState<string>(""); 
    const [buttonName, setButtonName] = useState<string>(""); 
    const [isOnProcess, setIsOnProcess] = useState<boolean>(false);
    const [tobeUpdatedData, setTobeUpdatedData] = useState<HealthHubContentWithOrganisation>(null);

    const [blobFile, setBlobFile] = useState<File>(null);
    const [url, setUrl] = useState<string>("");
    const [fileId, setFileId] = useState<string>("");
    const imageInputRef = useRef<{ clearImageField: () => void }>(null);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [toBeDeletedData, setToBeDeletedData] = useState<HealthHubContentWithOrganisation>(null);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [action, setAction] = useState<string>("");

    const [organisations, setOrganisations] = useState<Organisation[]>([]);
    const [isSetupModalOpen, setIsSetupModalOpen] = useState<boolean>(false); 
    const [selectedOrganisations, setSelectedOrganisations] = useState<string[]>([]);
    const [tobeSetuped, setTobeSetuped] = useState<HealthHubContentWithOrganisation>();
    const [isSaving, setIsSaving] = useState<boolean>(false);
    
    const [search, setSearch] = useState<string>("");
    const [searchOrg, setSearchOrg] = useState<string>("");
    const [isSearching, setIsSearching] =  useState<boolean>(false);
    let timer = useRef<number | null> (null);

    const [highlightedItem, setHighlightedItem] = useState<string | null>(null);
    const targetRowRef = useRef(null);

    const { data, loading, error } = useHealthHubContentsWithOrganisation();
    const { data : organisationData, loading : organisationLoading} = useOrganisation();

    const [addHealthHubHook] = useMutation(addHealthHubContent,{
        onCompleted : () =>{
            toast.success("Health Hub Link was successfully added");
        },
        onError : () =>{
            toast.error("Something went wrong whilst Adding the Health Hub Link . Please try again or if issue persists, please contact us");
        }
    });

    const [updateHealthHubHook] = useMutation(editHealthHubContent,{
        onCompleted : () =>{
            toast.success("Health Hub Link  was successfully updated");
        },
        onError : () =>{
            toast.error("Something went wrong whilst updating the Health Hub Link . Please try again or if issue persists, please contact us");
        }
    });

    const [removeHealthHubHook] = useMutation(removeHealthHubContent,{
        onCompleted : () =>{
            toast.success("Health Hub Link  was successfully deleted");
        },
        onError : () =>{
            toast.error("Something went wrong whilst deleting the Health Hub Link . Please try again or if issue persists, please contact us");
        }
    });

    useEffect(() => {
        if(data){
            setLinks(data.health_hub_contents);
            setLinksFiltered(data.health_hub_contents);
        }
    },[data]);

    useEffect(() =>{
        if(organisationData) {
            setOrganisations(organisationData.organisations);
        }
    },[organisationData]);

    const { uploadImage } = useImageUpload();
    const [deleteFile] = useMutation(executeDeleteImage);

    const Reloader = () =>{
        setTimeout(function() {
            window.location.reload();
        },700)
    }
    const DeleteFileExecute = async (fileIdCloud : string) => {
        if(!fileIdCloud){
            return; 
        }
        try{
            await deleteFile({
                variables : {
                    fileId : fileIdCloud
                },
            })
        }
        catch(err){
            console.log(err);
        }
    }

    useEffect(() => {
        if(links) {
            if(preOpenedItem) {
                const getItem = links.find((u) => u._id === preOpenedItem);
                if(getItem) {
                    HandleEdit(getItem);
                    setHighlightedItem(preOpenedItem);
                }
            }
        }
    },[links,preOpenedItem]);

    useEffect(() => {

        if(highlightedItem && targetRowRef) {
            targetRowRef.current.scrollIntoView({
                behavior: 'smooth', // Smooth scrolling
                block: 'nearest',   // Align the row to the nearest edge
            });
        }

    },[highlightedItem]);
//#endregion Initialization
//#region Add and Edit Function Combined
// The Add and edit function here is combine due to use of single modal approach
// as the modal here is not moved into a component as its not gonna be reused frequently same as the table
    const toggleModal = () =>{
        setIsModalOpen(!isModalOpen);
    }
    const triggerSubmit = ()=>{
        formikRef.current.submitForm();
    }
    const HandleAdd = () =>{
        imageInputRef.current?.clearImageField();
        setInitialValue( {
            id : "1",
            name : "",
            url : "",
            image : "",
            image_id : "",
            alt : "",
            description : ""
        })
        setModalName("Add Link");
        setButtonName("Add");
        setAction("add");
        setIsModalOpen(true);
    }
    const HandleEdit = (link : HealthHubContentWithOrganisation ) =>{
        // imageInputRef.current?.clearImageField();
        setInitialValue({
            id : link._id,
            image_id : link.image_id,
            name : link.name,
            description : link.description,
            url : link.link,
            image : link.image,
            alt : link.alt
        });
        setTobeUpdatedData(link);
        setModalName("Edit Link")
        setButtonName("Edit");
        setAction("edit");
        setIsModalOpen(true);
    }


    const HandleSubmit  = async ( formData : HealthHubLinks) => {
        try
        {
            if(isOnProcess){
                return;
            }
            setIsOnProcess(true);
            if(blobFile){
                const isUploaded = await uploadImage(url,blobFile);
                if(!isUploaded){
                    toast.error("Something went wrong whilst uploading your image. Please try again or if issue persists, please contact us");
                    
                    setIsOnProcess(false);    
                    return;
                }
            }
            if(action === "add"){
                // do add here
                addHealthHubHook({
                    variables :{
                        record : {
                            name : formData.name,
                            alt : formData.alt,
                            description : formData.description,
                            image : formData.image,
                            image_id : fileId,
                            link : formData.url
                        }
                    }
                })
                .then(async (res)=>{
                    if(res.errors){
                        setIsOnProcess(false);
                        await DeleteFileExecute(fileId);
                    }
                    else{
                        toggleModal();
                        Reloader();
                    }
                })
                .catch(()=>{
                    setIsOnProcess(false);
                })
            }
            else if (action === "edit"){
                // do edit here
                updateHealthHubHook({
                    variables :{
                        _id : formData.id,
                        record : {
                            name : formData.name,
                            alt : formData.alt,
                            description : formData.description,
                            image : formData.image,
                            image_id : fileId || formData.image_id,
                            link : formData.url
                        }
                    }
                })
                .then(async (res)=>{
                    // if the adding to database failed roll back the uploaded file
                    if(res.errors){
                        await DeleteFileExecute(fileId);
                        setIsOnProcess(false);
                    }
                    else{
                        // if the adding to database is successful delete the previous image
                        if(fileId) {
                            await DeleteFileExecute(tobeUpdatedData.image_id);
                        }
                        toggleModal();
                        Reloader();
                    }
                })
                .catch(()=>{
                    setIsOnProcess(false);
                })
            }
    
        }
        catch
        {
            setIsOnProcess(false);    
        }
    }
//#endregion Add and Edit Function Combined
//#region Delete Functions
    const HandleDelete = (link : HealthHubContentWithOrganisation ) =>{
        setToBeDeletedData(link);
        setIsDeleteModalOpen(true);
    }

    const handleDeleteModalCancel = () => {
        setIsDeleteModalOpen(false);
    }

    const handleDeleteModalConfirm = async () => {
        try{
            if(isDeleting){
                return;
            }
            setIsDeleting(true);
    
            await removeHealthHubHook({
                variables : {
                    _id : toBeDeletedData._id
                }
            })
            .then(async (res)=>{
                if(res.errors){
                    setIsDeleting(false);
                }
                else{
                    await DeleteFileExecute(toBeDeletedData.image_id);
                    Reloader();
                }
            })
            .catch(()=>{
                setIsDeleting(false);
            })
    
        }
        catch{
            setIsDeleting(false);
        }

    }
//#endregion Delete Functions
//#region Image sync 
const HandleImageChange = (url : string, fileId : string, blob : File) => {
    setUrl(url);
    setFileId(fileId);
    setBlobFile(blob);
}

const IsFetchingToggle = (isGetting : boolean) =>{
    setIsOnProcess(isGetting);
}
//#endregion Image sync    

//#region Setup Organisation
const HandleOpenSetup = (item : HealthHubContentWithOrganisation) => {
    ToggleModalSetup();
    setTobeSetuped(item);
    setSelectedOrganisations(item.using_organisation_ids);
}

const ToggleModalSetup = () => {
    setIsSetupModalOpen(!isSetupModalOpen);
}

const HandleSaveSelectedOrganisation = async (selected : string[]) => {
    setIsSaving(true);
    const isUpdated = await updateHealthHubHook({
        variables : {
            _id : tobeSetuped._id,
            record : {
                using_organisation_ids : selected
            }
        },
    }).then((res) => res.errors ? false : true).catch(()=>false);

    
    if(!isUpdated) {
        setIsSaving(true);
        return;
    }

    ToggleModalSetup();
    Reloader();
}
const HandleSearch = (value : string) => {
    filterChallenge(value, searchOrg);
}

const HandleOrganisationChange = (value : string) => {
    filterChallenge(search, value);
    setSearchOrg(value);
}

const HandleClear = () => {
    setSearchOrg("");
    setSearch("");
    filterChallenge("","");
}

const filterChallenge = (searchText : string, searchOrganisation : string) => {

    searchText = searchText?.trim().toLowerCase();
    setIsSearching(true);
    if(timer?.current) {
        clearTimeout(timer.current);
        timer.current = null;
    }

    timer.current = window.setTimeout(() => {
        const filtered = links.filter( u => 
            (!searchText || u.name.toLowerCase().includes(searchText) || u.description.toLowerCase().includes(searchText)) &&
            (!searchOrganisation || u.using_organisation_ids?.includes(searchOrganisation))
        );
        setLinksFiltered(filtered);
        setIsSearching(false);
    },1500);

}
//#endregion Setup Organisation

return (
        <>
            <Breadcrumbs 
                crumbs={breadcrumbsData}
            />
            {
                error &&
                <Alert color="danger">
                    Oops, we’re having trouble getting the health hub links. Please give it a minute, and if it doesn’t resolve, let us know.
                </Alert>
            }    
            <Row className="pb-2">
                <Col className="d-flex">
                    <label className="mt-2 mr-2">Search: </label>
                    <Input className="w-100" type="text" value={search} onChange={(e) => { setSearch(e.currentTarget.value); }} onKeyUp={(e) => { HandleSearch(e.currentTarget.value); }} placeholder="Search name and description here" />
                </Col>
                <Col className="d-flex">
                    <label className="mt-2 mr-2">Organisation: </label>
                    <Input className="w-100" type="select" disabled={organisationLoading} onChange={(e) => ( HandleOrganisationChange(e.currentTarget.value) )} value={searchOrg}>
                        <option value="">All Organisation</option>
                        {
                            organisations.map((item, index) => 
                                <option value={item._id} key={index}>{item.name}</option>
                            )
                        }
                    </Input>
                    <Button color="secondary ml-3" onClick={HandleClear}>Clear</Button>
                    <Button color="primary" style={{width:300}} onClick={HandleAdd}>Add Link</Button>
                </Col>
            </Row>
            <div className="table-responsive table-max-height">
                <Table striped bordered>
                    <thead>
                        <tr>
                            <th>Image</th>
                            <th style={{width:"250px"}}>Name & Description</th>
                            <th style={{width:"250px"}}>Link</th>
                            <th>Organisations</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (loading || isSearching) && 
                            <tr>
                                <td colSpan={5}>
                                    <Loading />
                                </td>
                            </tr>
                        }
                        {(linksFiltered.length === 0 && !loading) && 
                            <tr>
                                <td colSpan={5} className="text-center">No Data Found</td>
                            </tr>
                        }
                        {
                            !isSearching && linksFiltered.map((link, key) => {
                                return (
                                    <tr key={key}
                                        className={ link._id === highlightedItem ? "table-bg-highlighted" : "" }
                                        ref={ link._id === highlightedItem ? targetRowRef : null } 
                                    >
                                        <td>
                                            <img
                                                style={{
                                                    borderRadius: "0.375rem",
                                                    marginBottom: "1rem",
                                                    height : "100px"
                                                }}
                                                src={getImageSmallUrl(link.image_id)}
                                                alt={link.alt}
                                            />
                                        </td>
                                        <td>
                                            <div className="font-weight-bold">{link.name}</div>
                                            <div className="table-description-details">
                                                { link.description.length > maxLength ? 
                                                link.description.slice(0, maxLength) + " ..."
                                                : link.description }
                                            </div>
                                        </td>
                                        <td >
                                            <div style={{ width : 200, maxWidth : 200, 
                                                    wordWrap :"break-word", overflowWrap :"break-word", 
                                                    whiteSpace: "pre-wrap" }} >
                                                <a href={link.link} target="_blank" rel="noreferrer">
                                                    {link.link}
                                                </a>
                                            </div>
                                        </td>
                                        <td >
                                            <div className="text-capitalize" 
                                                style={{ width : 150, maxWidth : 150, 
                                                    wordWrap :"break-word", overflowWrap :"break-word", 
                                                    whiteSpace: "pre-wrap" }}
                                            >{link.using_organisations.map(u => u.name).join(", ")}</div>
                                        </td>
                                        <td>
                                            <Button color="primary" size='sm'onClick={()=>{ HandleEdit(link) }} >Edit</Button>
                                            <Button color="primary" size='sm'onClick={()=>{ HandleOpenSetup(link) }} >Organisations</Button>
                                            <Button color="danger" size='sm' onClick={()=>{ HandleDelete(link)}} >Delete</Button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
            <Modal
                id="healthhub-edit-modal"
                className="modal-lg"
                backdrop={true}
                isOpen={isModalOpen}>
                <ModalHeader toggle={toggleModal} className="pb-0">
                    <p className="text-lg">{modalName}</p>
                </ModalHeader>
                <ModalBody>
                    <Formik
                        innerRef={formikRef}
                        initialValues={initialValue}
                        validationSchema={EditHealthHubValidator}
                        onSubmit={async (value : HealthHubLinks) =>{
                            await HandleSubmit(value);
                        }}
                    >
                        {({ errors, touched }) => (
                            <Form>
                                <TextFormInput
                                    name={"name"}
                                    placeholder="Enter Name"
                                    label="Name"
                                    required={true}
                                    className="mb-3"
                                />
                                <div className="form-group">
                                    <label>Alt Text * <small className="text-muted">(Shows when the image is unavailable)</small></label>
                                    <Field
                                        className="form-control"
                                        name="alt"
                                        placeholder="Enter Alternative text"
                                        as="textarea"
                                    />
                                    <FormError>{touched.alt && (errors.alt)}</FormError>
                                </div>
                                <div className="form-group">
                                    <label>Description *</label>
                                    <Field
                                        className="form-control"
                                        name="description"
                                        placeholder="Enter Description"
                                        as="textarea"
                                    />
                                    <FormError>{touched.description && (errors.description)}</FormError>
                                </div>
                                <TextFormInput
                                    name={"url"}
                                    placeholder="Enter Link ex. https://www.google.com"
                                    label="Link"
                                    required={true}
                                    className="mb-3"
                                />
                                <ImageUploadInput
                                    ref={imageInputRef}
                                    formikRef={formikRef}
                                    transferFunction={HandleImageChange}
                                    isGettingUrl={IsFetchingToggle}
                                    imageName="image"
                                    imageIdName="image_id"
                                    isDisabled={isOnProcess}
                                />

                            </Form>
                        )}
                    </Formik>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" disabled={isOnProcess} onClick={toggleModal} >Close </Button>
                    <Button color="primary" disabled={isOnProcess} onClick={triggerSubmit} >{buttonName}</Button>
                </ModalFooter>
            </Modal>
            <OrganisationSelectorModal
                toggleModal={ToggleModalSetup}
                modalOpen={isSetupModalOpen}
                handleSave={HandleSaveSelectedOrganisation}
                organisations={organisations}
                isButtonDisabled={isSaving}
                selectedOrgs={selectedOrganisations}
            
            />
            <PromptDialogs
                isOpen={isDeleteModalOpen}
                onCancel={handleDeleteModalCancel}
                onConfirm={handleDeleteModalConfirm}
                title="Delete Link"
                promptMessage="Are you sure you want to delete this Link?"
                confirmBtnTxt="Delete"
                isOnProcess={isDeleting}
            />   
        </>
    )
}

export { HealthHubEditorPage as HealthHubEditor };