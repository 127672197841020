import { gql, useQuery } from "@apollo/client";

export function useHealthHubContents() {
  return useQuery(
    gql`
      query getHealthHubContents {
        health_hub_contents_for_dashboard {
          _id
          name
          description
          image
          image_id
          link
          alt
        }
      }
    `,
    {
      fetchPolicy : 'network-only'
    }
  );
}


export function useHealthHubContentsWithOrganisation() {
  return useQuery(
    gql`
      query getHealthHubContents {
        health_hub_contents{
          _id
          name
          description
          image
          image_id
          link
          alt
          using_organisation_ids
          using_organisations {
            _id
            name
          }
        }
      }
    `,
    {
      fetchPolicy : 'network-only'
    }
  );
}


export function useHealthHubContentsForOrganisation(organisation_id : string) {
  return useQuery(
    gql`
      query getHealthHubContentsForOrganisation($organisation_id: String!) {
        health_hub_contents_for_org (organisation_id: $organisation_id) {
          _id
          name
          description
          image
          image_id
          link
          alt
        }
      }
    `,
    {
      variables: { organisation_id : organisation_id },
      fetchPolicy : 'network-only'
    }
  );
}