import { gql, useQuery } from "@apollo/client";
import moment from "moment";
export const ContentOfTheWeekType = {
  TOPIC : 1,
  CHALLENGE : 2
}

export function useContentOfTheWeek(type: number) {
  return useQuery(
    gql`
      query getContentOfTheWeekByType($type: Int!) {
        content_of_the_weeks_by_type(type: $type) {
          _id
          title
          description
          image
          image_id
          start_date
          end_date
          type
        }
      }
    `,
    {
      variables: { type: type },
      fetchPolicy : 'network-only'
    }
  );
}

export function useContentOfTheWeekWithOrganisation(type: number) {
  return useQuery(
    gql`
      query getContentOfTheWeekByTypeWithOrganisation($type: Int!) {
        content_of_the_weeks_by_type(type: $type) {
          _id
          title
          description
          image
          image_id
          start_date
          end_date
          type
          using_organisation_ids
          using_organisations {
            _id
            name
          }

        }
      }
    `,
    {
      variables: { type: type },
      fetchPolicy : 'network-only'
    }
  );
}

export function useContentOfTheWeekByDate(type: number, date : Date) {
  return useQuery(
    gql`
      query getContentOfTheWeekByTypeAndDate($type: Int!, $date : Date!) {
        content_of_the_week_by_date_and_type(type: $type, date: $date) {
          _id
          title
          description
          image_id
          start_date
          end_date
        }
      }
    `,
    {
      variables: { 
        type: type, 
        date : moment(date).format("YYYY-MM-DDTHH:mm:ssZ")
      },
      fetchPolicy : 'network-only'
    }
  );
}

export function useContentOfTheWeekForOrganisation(type: number, organisation_id : string) {
  return useQuery(
    gql`
      query getContentOfTheWeekByTypeAndDate($type: Int!, $organisation_id : String!) {
        content_of_the_weeks_for_org(type: $type, organisation_id: $organisation_id) {
          _id
          title
          description
          image_id
          start_date
          end_date
        }
      }
    `,
    {
      variables: { 
        type, 
        organisation_id
      },
      fetchPolicy : 'network-only'
    }
  );
}