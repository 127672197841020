import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { Table, Button,  Modal, ModalHeader, ModalBody, ModalFooter, Alert, FormGroup, Input, Label } from 'reactstrap';
import TextFormInput from "../../components/Shared/Forms/TextFormInput";
import FormError from "../../components/Shared/Forms/FormError";
import {
    DASHBOARD_ROOT,
} from "../../components/Routes/routingConstants";
import {EmpowerTask } from "../../interfaces/models/EmpowerTask"
import Loading from '../../components/Loading';
import React, { useState, useRef } from "react"
import { Field, Form, Formik, FormikProps } from "formik";
import useImageUpload from "../../hooks/useImageUpload";
import { useMutation, useQuery } from "@apollo/client";
import { executeDeleteImage} from "../../graphql/queries/getImageUploadURL";
import EditEmpowerMeNudgeValidator from "../../validators/EditEmpowerMeNudgeValidator"
import { getImageSmallUrl } from "../../helper/getImageUrl";
import toast from "react-hot-toast";
import { getEmpowerMeTasksSorted, editEmpowerMeNudge } from "../../graphql/empower-me/EmpowerMeQueries"
import ImageUploadInput from '../../components/ContentManagement/ImageUploadInput';

interface DataFormik {
    id : string,
    image_id : string;
    description : string;
    name : string;
}

const EmpowerMeContentEditorPage = () => {

//#region Initialization
    const breadcrumbsData = [
        { name: 'Content List', path: `${DASHBOARD_ROOT}/content-management/` },
        { name: 'Edit Empower ME', path: `${DASHBOARD_ROOT}/content-management/empower-me` },
    ];

    const CATEGORY_PHYSICAL = "As a physically proactive person";
    const CATEGORY_NUTRITIONAL = "As a nutritionally aware person";
    const CATEGORY_PSYCHOLOGICAL = "As a psychologically proactive person";
    const CATEGORY_MINDFUL = "As a mindful individual";

    const [empowerMeItems, setEmpowerMeItems] = useState<EmpowerTask[]>([]);

    const empowerMeCategory : Array<string> = [
        CATEGORY_PHYSICAL, CATEGORY_NUTRITIONAL, CATEGORY_PSYCHOLOGICAL, CATEGORY_MINDFUL
    ]

    const [selectedCategory, setSelectedCategory] = useState<string>(CATEGORY_PHYSICAL);

    const [tobeEdited, setToBeEdited] = useState<DataFormik>(null);
    const formikRef = useRef<FormikProps<DataFormik>>(null);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isOnProcess, setIsOnProcess] = useState<boolean>(false);


    const [blobFile, setBlobFile] = useState<File>(null);
    const [url, setUrl] = useState<string>("");
    const [fileId, setFileId] = useState<string>("");
    const imageInputRef = useRef<{ clearImageField: () => void }>(null);

    const [isActivating, setIsActivating] = useState<boolean>(false);

    const [editEmpowerMeNudgeHook] = useMutation(editEmpowerMeNudge,{
        onCompleted : () =>{
            toast.success("Empower ME Nudge was successfully updated");
        },
        onError : () =>{
            toast.error("Something went wrong whilst Updating Empower ME Nudge . Please try again or if issue persists, please contact us");
        }
    });

    const {
        loading : emLoading,
        error : emError,
      } = useQuery(getEmpowerMeTasksSorted, {
        onCompleted(data) {
            setEmpowerMeItems(data.empower_me_tasks_sorted_by_actives);
        },
        onError(error) {
          console.log(error);
        },
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true, // Useful for invoking `onCompleted` during `refetch` calls
      });

      const Reloader = () =>{
        setTimeout(function() {
            window.location.reload();
        },1500)
    }
    const DeleteFileExecute = async (fileIdCloud : string) => {
        if(!fileIdCloud){
            return; 
        }
        try{
            await deleteFile({
                variables : {
                    fileId : fileIdCloud
                },
            })
        }
        catch(err){
            console.log(err);
        }
    }

    const { uploadImage } = useImageUpload();
    const [deleteFile] = useMutation(executeDeleteImage);

//#endregion Initialization
//#region Edit Functions
    const ToggleModal = () => {
        // imageInputRef.current?.clearImageField();
        setIsModalOpen(!isModalOpen);
    }

    const HandleCategoryChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        setSelectedCategory(event.target.value)
    }

    const HandleEdit = (item : EmpowerTask) =>{

        setToBeEdited({
            id : item._id,
            name : item.name,
            description : item.description,
            image_id : item.image_id
        })

        ToggleModal();
    }

    const HandleSubmit = async (formData : DataFormik) => {
        if(isOnProcess){
            return;
        }

        setIsOnProcess(true)

        if(blobFile){
            const isUploaded = await uploadImage(url,blobFile);
            if(!isUploaded){
                toast.error("Something went wrong whilst uploading your image. Please try again or if issue persists, please contact us");
                setIsOnProcess(false);    
                return;
            }
        } else {
            setFileId(null);
        }

        let processedImage = null
        if(fileId) {
            processedImage = fileId
        }
        else if (formData.image_id) {
            processedImage = formData.image_id
        }

        editEmpowerMeNudgeHook({
            variables :{
                _id : tobeEdited.id,
                record : {
                    name : formData.name,
                    description : formData.description,
                    image_id : processedImage,
                }
            }
        })
        .then(async (res)=>{
            if(res.errors){
                setIsOnProcess(false);
                await DeleteFileExecute(fileId);
            }
            else{
                if(fileId && tobeEdited.image_id) {
                    await DeleteFileExecute(tobeEdited.image_id);
                }
                Reloader();
            }
        })
        .catch(()=>{
            setIsOnProcess(false);
        })
    }

    const ToggleActivation = (item : EmpowerTask) => {

        if(isActivating) {
            return;
        }
        setIsActivating(true);
        let newStatus = ! (item.is_active || false);

        if(newStatus === true) {
            //check if there is 5 active items related to category
            const empowerMeActiveItems = empowerMeItems.filter( (u : EmpowerTask )=>  u.task_category.description === item.task_category.description && u.is_active === true).length;
            if(empowerMeActiveItems === 10) { 
                toast.error("Sorry, there can be a maximum of 10 active items per category.");
                setIsActivating(false);
                return;
            }
        }

        editEmpowerMeNudgeHook({
            variables :{
                _id : item._id,
                record : {
                    is_active : newStatus
                }
            }
        })
        .then(async (res)=>{
            if(res.errors){
                setIsActivating(false);
            }
            else{
                setEmpowerMeItems((prevItems : EmpowerTask[]) => {
                    const index = prevItems.findIndex(u => u._id === item._id);
                    if (index === -1) return prevItems;
                
                    const newItems = [...prevItems];
                    const updatedItem = { ...newItems[index], is_active: newStatus };
                    newItems[index] = updatedItem;
                    return newItems;
                });
                setIsActivating(false);
            }
        })
        .catch(()=>{
            setIsActivating(false);
        })
    }

//#endregion Edit Functions
    
//#region Image sync 
const HandleImageChange = (url : string, fileId : string, blob : File) => {
    setUrl(url);
    setFileId(fileId);
    setBlobFile(blob);
}

const IsFetchingToggle = (isGetting : boolean) =>{
    setIsOnProcess(isGetting);
}
//#endregion Image sync 
    return (
        <>
            <Breadcrumbs 
                crumbs={breadcrumbsData}
            />
            <FormGroup>
                <Label for="exampleSelect">
                    Category
                </Label>
                <Input
                    name="select"
                    type="select"
                    onChange={HandleCategoryChange}
                >
                    {
                        empowerMeCategory.map((item : string, index : number) => {
                            return (
                                <option value={item} key={index}>{item}</option>
                            )
                        })
                    }
                </Input>
            </FormGroup>
            {
                emError &&
                <Alert color="danger">
                    Oops, we’re having trouble getting the EM nudges. Please give it a minute, and if it doesn’t resolve, let us know.
                </Alert>
            }
            <div className="table-responsive table-max-height">
                <Table bordered  >
                    <thead>
                        <tr className="sticky-top bg-white">
                            <th>Image</th>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Category</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            emLoading &&
                            <tr>
                                <td colSpan={5}>
                                    <Loading />
                                </td>
                            </tr>
                        }
                        {
                            empowerMeItems.length === 0 && !emLoading &&
                            <tr>
                                <td colSpan={5} className="text-center">No Data Found</td>
                            </tr>
                        }
                        {
                            empowerMeItems.filter(u => u.task_category.description === selectedCategory).map((item : EmpowerTask, index : number) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <img
                                                style={{
                                                    borderRadius: "0.375rem",
                                                    marginBottom: "1rem",
                                                    height : "100px",
                                                    width : "150px",
                                                    objectFit :"cover"
                                                }}
                                                src={getImageSmallUrl(item.image_id ?? item.task_category.image)}
                                                alt={"test"}
                                            />
                                        </td>
                                        <td>
                                            <div style={{ width : 250, maxWidth : 250, 
                                                wordWrap :"break-word", overflowWrap :"break-word", 
                                                whiteSpace: "normal" }}>
                                                {item.name}
                                            </div>
                                        </td>
                                        <td>
                                            <div style={{ width : 250, maxWidth : 250, 
                                                wordWrap :"break-word", overflowWrap :"break-word", 
                                                whiteSpace: "normal" }}>
                                                {item.description}
                                            </div>
                                        </td>
                                        <td>{item.task_category.description}</td>
                                        <td>
                                            
                                            <Button color="primary" size='sm'onClick={()=>{ HandleEdit(item) }} >Edit</Button>
                                            {
                                                item.is_active === true ? 
                                                <Button color="danger" disabled={isActivating} size='sm'onClick={()=>{ ToggleActivation(item) }} >Deactivate</Button>
                                                :
                                                <Button color="success" disabled={isActivating} size='sm'onClick={()=>{ ToggleActivation(item) }} >Activate</Button>
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        
                    </tbody>
                </Table>
            </div>
            <Modal
                id="empowerme-edit-modal"
                className="modal-lg"
                backdrop={true}
                isOpen={isModalOpen}>
                <ModalHeader toggle={ToggleModal} className="pb-0">
                    <p className="text-lg">Edit EM Nudge</p>
                </ModalHeader>
                <ModalBody>
                    <Formik
                        innerRef={formikRef}
                        initialValues={tobeEdited}
                        validationSchema={EditEmpowerMeNudgeValidator}
                        onSubmit={async (value : DataFormik) =>{
                            await HandleSubmit(value);
                        }}
                    >
                        {({ errors, touched }) => (
                            <Form>
                                <TextFormInput
                                    name={"name"}
                                    placeholder="Enter Name"
                                    label="Name"
                                    required={true}
                                    className="mb-3"
                                />
                                <div className="form-group">
                                    <label>Description </label>
                                    <Field
                                        className="form-control"
                                        name="description"
                                        placeholder="Enter Description"
                                        as="textarea"
                                    />
                                    <FormError>{touched.description && (errors.description)}</FormError>
                                </div>
                                <ImageUploadInput
                                    ref={imageInputRef}
                                    formikRef={formikRef}
                                    transferFunction={HandleImageChange}
                                    isGettingUrl={IsFetchingToggle}
                                    imageName="image"
                                    imageIdName="image_id"
                                    extensionAllowed="image/jpeg,image/jpg"
                                    isDisabled={isOnProcess}
                                />

                            </Form>
                        )}
                    </Formik>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" disabled={isOnProcess } onClick={ToggleModal} >Close</Button>
                    <Button color="primary" disabled={isOnProcess } onClick={() => { formikRef?.current.submitForm(); }} >Edit</Button>
                </ModalFooter>
            </Modal>
        </>
    )

}


export { EmpowerMeContentEditorPage as EmpowerMeContentEditor };