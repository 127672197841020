import { gql, useQuery } from "@apollo/client";

export function useTaskSequenceBuckets() {
  return useQuery(gql`
    query getTaskSequenceBuckets {
      task_sequence_buckets {
        _id
        name
        description
        type
        task_sequences_ids
        status
        applied_to_organisations
      }
    }
  `);
}

export function useTaskSequenceBucketsWithOrganisation() {
  return useQuery(gql`
    query getTaskSequenceBucketsWithOrganisation {
      task_sequence_buckets {
        _id
        name
        description
        type
        task_sequences_ids
        applied_to_organisations
        status
        using_organisation_ids
        using_organisations {
          _id
          name
        }
      }
    }
  `);
}

export function useTaskSequenceBucketForOrganisation(organisation_id : string) {
  return useQuery(gql`
    query getTaskSequenceBucketsForOrganisation($organisation_id: MongoID!) {
      task_sequence_bucket_for_org(organisation_id: $organisation_id) {
          _id
          name
          description
          type
        }
      }
    `,
    {
      variables: { organisation_id }
    }
);
}

