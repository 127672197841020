import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import {
    DASHBOARD_ROOT,
} from "../../components/Routes/routingConstants";
import { useState , useEffect, useRef } from 'react';
import { Table, Button,  Modal, ModalHeader, ModalBody, ModalFooter, Alert, Input, Label } from 'reactstrap';
import { useHistory } from "react-router-dom";
import ImageUploadInput from '../../components/ContentManagement/ImageUploadInput';
import { Form, Formik, FormikProps } from "formik";
import { useTaskSequencesWithoutSequence } from "../../hooks/useTaskSequencesForManagement"
import { getImageSmallUrl } from "../../helper/getImageUrl";
import Loading from '../../components/Loading';
import DcImage from "../../assets/img/brand/DC_logo_mini_for web.png"
import TextFormInput from '../../components/Shared/Forms/TextFormInput';
import AddTaskSequenceValidator from '../../validators/AddTaskSequenceValidator';
import { addCategory, updateCategory } from "../../graphql/content-management/addCategories"
import { addTaskSequence, updateTaskSequence } from "../../graphql/content-management/addTaskSequences"
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import useImageUpload from "../../hooks/useImageUpload";
import { executeDeleteImage } from "../../graphql/queries/getImageUploadURL";

//#region Interfaces
interface DataFormik {
    _id : string;
    name : string;
    image : string;
    image_id : string;
    category_id? : string;
}

interface HookReturn {
    ok : boolean;
    data : string;
}

interface TaskSequence { 
    name : string;
    _id: string;
    include_in_rotation : boolean
    image : string
    category? : string;
}

interface ModalLabels {
    name : string;
    buttonName : string;
    action : string;
}
//#endregion
const SequenceEditorPage = () => {

//#region Initializations
    const breadcrumbsData = [
        { name: 'Content List', path: `${DASHBOARD_ROOT}/content-management/` },
        { name: 'Edit Day Sequences', path: `${DASHBOARD_ROOT}/content-management/day-sequences` },
    ];
    
    const history = useHistory();

    const [taskSequences, setTaskSequences] = useState<TaskSequence[]>([]);
    const [taskSequencesFiltered, setTaskSequencesFiltered] = useState<TaskSequence[]>([]);

    const formikRef = useRef<FormikProps<DataFormik>>(null);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isOnProcess, setIsOnProcess] = useState<boolean>(false);
    const [selectedValue, SetSelectedValue] = useState<DataFormik>();
    const [modalLabels, setModalLabels] = useState<ModalLabels>({
        name : "",
        buttonName : "",
        action : ""
    });

    const [blobFile, setBlobFile] = useState<File>(null);
    const [url, setUrl] = useState<string>("");
    const [fileId, setFileId] = useState<string>("");
    const imageInputRef = useRef<{ clearImageField: () => void }>(null);

    const [isSearching, setIsSearching] =  useState<boolean>(false);
    let timer = useRef<number | null> (null);
    
    const { data, loading, error } = useTaskSequencesWithoutSequence();

    const [addCategoryHook] = useMutation(addCategory,{
        onCompleted : () =>{

        },
        onError : () =>{
            toast.error("Something went wrong whilst adding Category. Please try again or if issue persists, please contact us");
        }
    });

    const [updateCategoryHook] = useMutation(updateCategory,{
        onCompleted : () =>{

        },
        onError : () =>{
            toast.error("Something went wrong whilst updating Category . Please try again or if issue persists, please contact us");
        }
    });

    const [addTaskSequenceHook] = useMutation(addTaskSequence,{
        onCompleted : () =>{
            toast.success("Task Sequence was successfully added");
        },
        onError : () =>{
            toast.error("Something went wrong whilst adding Task Sequence. Please try again or if issue persists, please contact us");
        }
    });

    const [updateTaskSequenceHook] = useMutation(updateTaskSequence,{
        onCompleted : () =>{
            toast.success("Task Sequence was successfully updated");
        },
        onError : () =>{
            toast.error("Something went wrong whilst updating Task Sequence . Please try again or if issue persists, please contact us");
        }
    });

    const { uploadImage } = useImageUpload();
    const [deleteFile] = useMutation(executeDeleteImage);
    
    useEffect(() =>{
        if(data){
            setTaskSequences(data.task_sequences_sort_by_name);
            setTaskSequencesFiltered(data.task_sequences_sort_by_name);
        }
    },[data])

    const HandleView = (item : TaskSequence) => {
        history.push(`${DASHBOARD_ROOT}/content-management/day-sequences/${item._id}?name=${item.name}`);
    }

    const Reloader = () =>{
        setTimeout(function() {
            window.location.reload();
        },1500)
    }
    
    const DeleteFileExecute = async (fileIdCloud : string) => {
        if(!fileIdCloud){
            return; 
        }
        try{
            await deleteFile({
                variables : {
                    fileId : fileIdCloud
                },
            })
        }
        catch(err){
            console.log(err);
        }
    }

    const HandleImageChange = (url : string, fileId : string, blob : File) => {
        setUrl(url);
        setFileId(fileId);
        setBlobFile(blob);
    }

    const IsFetchingToggle = (isGetting : boolean) =>{
        setIsOnProcess(isGetting);
    }

    const TriggerSubmit = () => {
        formikRef.current?.submitForm();
    }

    const ToggleModal = () => {
        if(isModalOpen){
            imageInputRef.current?.clearImageField();
        }
        setIsModalOpen(!isModalOpen);
        
    }

//#endregion Initializations
//#region Add and Edit Functions
    const HandleOpenAdd = () => {
        SetSelectedValue({
            _id : "1",
            name : "",
            image : "",
            image_id : ""
        })
        imageInputRef.current?.clearImageField();
        setModalLabels({
            name : "Add Task Sequence",
            buttonName : "Add",
            action : "add"
        })
        ToggleModal();
    }

    const HandleOpenEdit = (item : TaskSequence) => {
        SetSelectedValue({
            _id : item._id,
            name : item.name,
            image : "myImage.jpeg",
            image_id : item.image,
            category_id : item.category
        })
        setModalLabels({
            name : "Edit Task Sequence",
            buttonName : "Edit",
            action : "edit"
        })
        imageInputRef.current?.clearImageField();
        ToggleModal();
    }

    const HandleSubmit = async (formData : DataFormik) => {

        setIsOnProcess(true);
        if(blobFile){
            const isUploaded = await uploadImage(url,blobFile);
            if(!isUploaded){
                toast.error("Something went wrong whilst uploading your image. Please try again or if issue persists, please contact us");
                
                setIsOnProcess(false);    
                return;
            }
        }

        if(modalLabels.action === "add") {

            //add a record in category table to be linked in the task sequence
            const createCategory : HookReturn = await addCategoryHook({
                variables : {
                    record :{
                        description : formData.name,
                        image : fileId,
                        level : 1,//this is a preset for day sequence no need to change this 
                        parent_id : "628ad9c2d5f28e744f357406" // preset no need to change
                    }
                }
            }).then((res)=>res.errors ? {ok : false, data : null} : {ok : true, data : res.data.category_CreateOne.record._id}).catch(()=> {
                return {ok : false, data : null}
            });

            if(!createCategory.ok) {
                setIsOnProcess(false);
                await DeleteFileExecute(fileId);
                return;
            }

            const createSequence = await addTaskSequenceHook({
                variables : {
                    record : {
                        name : formData.name,
                        sequence : [],
                        include_in_rotation : false,
                        organisation_id : null,
                        category : createCategory.data,
                        image : fileId,
                    }
                }
            }).then((res)=>res.errors ? false : true).catch(()=>false);

            if(!createSequence) {
                setIsOnProcess(false);
                await DeleteFileExecute(fileId);
                return;
            }

            Reloader();

        }
        else if (modalLabels.action === "edit"){
            
            if(selectedValue.category_id) {
                //update in category table 
                const updateCategory = await updateCategoryHook({
                    variables : {
                        _id : selectedValue.category_id,
                        record :{
                            description : formData.name,
                            image : fileId || selectedValue.image_id,
                        }
                    }
                }).then((res)=>res.errors ? false : true).catch(()=> false);

                if(!updateCategory) {
                    setIsOnProcess(false);
                    await DeleteFileExecute(fileId);
                    return;
                }
            }

            const updateSequence = await updateTaskSequenceHook({
                variables : {
                    _id : selectedValue._id,
                    record : {
                        name : formData.name,
                        image : fileId || selectedValue.image_id,
                    }
                }
            }).then((res)=>res.errors ? false : true).catch(()=>false);

            if(!updateSequence) {
                setIsOnProcess(false);
                await DeleteFileExecute(fileId);
                return;
            } else {
                if(fileId && selectedValue.image_id) {
                    await DeleteFileExecute(selectedValue.image_id);
                }
            }

            Reloader();
        }
    }

//#endregion Add and Edit Functions
//#region Search Functions
const filter = (searchText : string) => {

    searchText = searchText?.trim().toLowerCase();
    setIsSearching(true);
    if(timer?.current) {
        clearTimeout(timer.current);
        timer.current = null;
    }

    timer.current = window.setTimeout(() => {
        const filtered = taskSequences.filter( u => 
            (!searchText || u.name.toLowerCase().includes(searchText))
        );
        setTaskSequencesFiltered(filtered);
        setIsSearching(false);
    },1500);

}
//#endregion Search Functions
    return (
        <>
            <Breadcrumbs 
                crumbs={breadcrumbsData}
            />
            {
                error &&
                <Alert color="danger">
                    Oops, we’re having trouble getting the Task Sequences. Please give it a minute, and if it doesn’t resolve, let us know.
                </Alert>
            }  
            <div className="d-flex  pb-2">
                <Label className="mt-2 mr-2">Search: </Label>
                <Input
                    type="text"
                    placeholder="Search"
                    className="form-control mr-3"
                    onKeyUp={(e) => filter(e.currentTarget.value)} />
                <Button color="primary" onClick={HandleOpenAdd}>Add</Button>
                
            </div>
            <div className="table-responsive table-max-height">
                <Table bordered>
                    <thead>
                        <tr className="sticky-top bg-white">
                            <th>Image</th>
                            <th>Name</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (loading || isSearching) && 
                            <tr>
                                <td colSpan={4}>
                                    <Loading />
                                </td>
                            </tr>
                        }
                        {(taskSequencesFiltered.length === 0 && !loading && !isSearching) && 
                            <tr>
                                <td colSpan={4} className="text-center">No Data Found</td>
                            </tr>
                        }
                        {
                            !isSearching && taskSequencesFiltered.map((item , index) =>{
                                return (
                                    <tr key={index}>
                                        <td>
                                            <img
                                                style={{
                                                    borderRadius: "0.375rem",
                                                    marginBottom: "1rem",
                                                    height : "100px"
                                                }}
                                                src={item.image ? getImageSmallUrl(item.image) : DcImage}
                                                alt={item.name}
                                            />
                                        </td>
                                        <td>{item.name}</td>
                                        <td>
                                            {
                                                item.include_in_rotation ? 
                                                <span className="badge bg-success text-white">Default</span>
                                                :
                                                <span >-</span>
                                            }
                                        </td>
                                        <td>
                                            <Button color="primary" size="sm" onClick={()=> HandleOpenEdit(item)}>Edit</Button>
                                            <Button color="secondary" size="sm" onClick={() => { HandleView(item); }}>View Sequences</Button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        
                    </tbody>
                </Table>
            </div>
            <Modal
                id="sequence-edit-modal"
                className="modal-lg"
                backdrop={true}
                isOpen={isModalOpen}>
                <ModalHeader toggle={ToggleModal} className="pb-0">
                    <p className="text-lg">{modalLabels.name}</p>
                </ModalHeader>
                <ModalBody>
                    <Formik
                        innerRef={formikRef}
                        initialValues={selectedValue}
                        validationSchema={AddTaskSequenceValidator}
                        onSubmit={async (value : DataFormik) =>{
                            await HandleSubmit(value);
                        }}
                    >
                        {() => (
                            <Form>
                                <TextFormInput
                                    name={"name"}
                                    placeholder="Enter Name"
                                    label="Name"
                                    required={true}
                                    className="mb-3"
                                />
                                <ImageUploadInput
                                    ref={imageInputRef}
                                    formikRef={formikRef}
                                    transferFunction={HandleImageChange}
                                    isGettingUrl={IsFetchingToggle}
                                    imageName="image"
                                    imageIdName="image_id"
                                />
                            </Form>
                        )}
                    </Formik>
                    
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" disabled={isOnProcess} onClick={ToggleModal} >Close </Button>
                    <Button color="primary" disabled={isOnProcess} onClick={TriggerSubmit} >{modalLabels.buttonName}</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export { SequenceEditorPage as SequenceEditor };