import { DASHBOARD_ROOT } from "../../components/Routes/routingConstants";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { Table, Button, Input } from 'reactstrap';
import { Organisation } from "../../interfaces/models/Organisation"
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useOrganisation } from '../../hooks/useOrganisation';
import Loading from "../../components/Loading";


const OrganisationListPage = () => {

    const breadcrumbsData = [
        { name: 'Content List', path: `${DASHBOARD_ROOT}/content-management/` },
        { name: 'Organisation List', path: `${DASHBOARD_ROOT}/content-management/organisation-list` },
    ];

    const history = useHistory();

    const { data : organisationData, loading : organisationLoading} = useOrganisation();

    const [organisations, setOrganisations] = useState<Organisation[]>([]);
    const [organisationsFiltered, setOrganisationsFiltered] = useState<Organisation[]>([]);

    const OpenOrganisation = (item : Organisation) => {
        history.push(`${DASHBOARD_ROOT}/content-management/organisation-list/${item._id}?name=${item.name}`);
    }

    const TriggerSearch = (e : React.KeyboardEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value?.toLowerCase().trim();
        setOrganisationsFiltered(organisations.filter(u => u.name.toLowerCase().includes(value)))
    }

    useEffect(() => {
        if(organisationData) {
            setOrganisations(organisationData.organisations);
            setOrganisationsFiltered(organisationData.organisations);
        }
    },[organisationData]);

    return  (
        <>
            <Breadcrumbs 
                crumbs={breadcrumbsData}
            />
            
            <div className="d-flex pb-3">
                <label className="pr-3 pt-1">Search: </label>
                <Input name="search" onKeyUp={(e) => { TriggerSearch(e); }} placeholder="Type Organisation name here to search"/>
            </div>

            <div className="table-responsive bg-white table-max-height"  >
                <Table bordered>
                    <thead>
                        <tr>
                            <th>Organisation Name</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (organisationsFiltered.length ===0 && !organisationLoading) && 
                            <tr>
                                <td colSpan={2} className="text-center">No Data Found</td>
                            </tr>
                        }
                        {
                            organisationLoading &&
                            <tr>
                                <td colSpan={2}>
                                    <Loading />
                                </td>
                            </tr>
                        }
                        {
                            organisationsFiltered.map((item, index) => 
                                <tr key={index}>
                                    <td>{item.name}</td>
                                    <td>
                                        <Button color="primary" size='sm'onClick={()=>{ OpenOrganisation(item) }} >View</Button>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </div>
        </>
    )
}

export { OrganisationListPage as OrganisationList };