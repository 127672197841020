import { useMemo } from "react";
import "../../_css/components/notification-categories-cards.css";
// import useTaskSequences from "../../hooks/useTaskSequences";
import useTaskSequencesCurrent from "../../hooks/useTaskSequencesCurrent";
import { useRouteMatch } from "react-router-dom";
import { TaskSequences } from "../../components/Tasks/TaskSequences";
import { sortSequencesByName } from "../../helper/sortSequencesByName";

const DeskCoachPage = () => {
  const match = useRouteMatch();
  const { data, loading, error } = useTaskSequencesCurrent();
  const sequences = useMemo(() => {
    if (data?.task_sequences_current) {
      return sortSequencesByName(data.task_sequences_current);
    }
  }, [data]);

  return (
    <TaskSequences
      loading={loading}
      error={error}
      sequences={sequences}
      match={match}
    />
  );
};

export { DeskCoachPage as DeskCoach };
