import { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, Input, Table, ModalFooter, Button } from "reactstrap";
import { Organisation } from "../../interfaces/models/Organisation";

interface Props {
    organisations : Organisation[];
    selectedOrgs : string[];
    isButtonDisabled : boolean;
    handleSave : (selected : string[]) => Promise<void>;
    modalOpen : boolean;
    toggleModal : () => void; 
}

export default function OrganisationSelectorModal({ organisations, isButtonDisabled, handleSave, modalOpen, toggleModal, selectedOrgs } : Props) {

    const [organisationsFiltered, setOrganisationsFiltered] = useState<Organisation[]>([]);
    const [selectedOrganisations, setSelectedOrganisations] = useState<string[]>([]);

    useEffect(() => {
        setOrganisationsFiltered(organisations);
    },[organisations]);
    
    useEffect(() => {
        setSelectedOrganisations(selectedOrgs);
    },[selectedOrgs]);

    const FilterOrganisation = (e : React.KeyboardEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value?.toLowerCase().trim();
        if(!value) {
            setOrganisationsFiltered(organisations);
            return;
        }

        setOrganisationsFiltered(organisations.filter(u => u.name.toLowerCase().includes(value)))
    }

    const OnOrganisationCheckboxChanged = (e : React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = e.target;
        let selected = [...selectedOrganisations];
        if (checked) {
            if(selected.indexOf(value) === -1) {
                selected.push(value);
            } 
        } 
        else {
            const getIndex = selected.indexOf(value);
            if(getIndex > -1) {
                selected.splice(getIndex, 1);
            } 
        }
        setSelectedOrganisations(selected);

    }

    const HandleSaveOrganisation = async () => {
        await handleSave(selectedOrganisations);
    }

    return (
        <Modal
            id="challenge-organisation-setup-modal"
            backdrop={true}
            isOpen={modalOpen}>
            <ModalHeader toggle={toggleModal} className="pb-0">
                <p className="text-lg">Select Organisation</p>
            </ModalHeader>
            <ModalBody>
                <div className="d-flex ">
                    <label className="mt-2 mr-2">Search: </label>
                    <Input className="w-100" type="text" onKeyUp={(e) => { FilterOrganisation(e); }} placeholder="Search Organisation here" />
                </div>
                <div className="table-responsive mt-3" style={{maxHeight:"400px"}}>
                    <Table striped bordered>
                        <thead>
                            <tr className="bg-white sticky-top">
                                <th style={{width:"50px"}}></th>
                                <th>Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                organisationsFiltered.length === 0 &&
                                <tr>
                                    <td colSpan={2} className="text-center">No Data Found</td>
                                </tr>
                            }
                            {
                                organisationsFiltered.map((item, index) => 
                                    <tr key={index}>
                                        <td >
                                            <input 
                                                type="checkbox" 
                                                className="form-control" 
                                                value={item._id}
                                                checked={selectedOrganisations?.includes(item._id)}
                                                onChange={OnOrganisationCheckboxChanged}
                                            />
                                        </td>
                                        <td > <div className="mt-2" >{item.name}</div> </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="">
                    <Button color="secondary" disabled={isButtonDisabled} onClick={toggleModal}>Cancel</Button>
                    <Button color="primary" disabled={isButtonDisabled} onClick={HandleSaveOrganisation}>Save</Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}