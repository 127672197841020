import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import {
    DASHBOARD_ROOT,
} from "../../components/Routes/routingConstants";
import { useState, useEffect, useRef  } from 'react';
import moment from 'moment';
import { Button, Alert, Input, Row, Col } from 'reactstrap';
import { DataFormik, ContentWeekEditorModal } from '../../components/ContentManagement/ContentWeekEditorModal';
import PromptDialog from "../../components/PromptDialog";
import { ContentOfTheWeekWithOrganisations } from '../../interfaces/models/ContentOfTheWeek'
import ContentWeekTable from '../../components/ContentManagement/ContentWeekTable';
import { useContentOfTheWeekWithOrganisation, ContentOfTheWeekType } from '../../hooks/useContentOfTheWeek'
import { addContentOfTheWeek, editContentOfTheWeek, removeContentOfTheWeek } from '../../graphql/content-management/addContentOfTheWeek'
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { executeDeleteImage } from "../../graphql/queries/getImageUploadURL";
import { useOrganisation } from "../../hooks/useOrganisation";
import { Organisation } from "../../interfaces/models/Organisation";
import OrganisationSelectorModal from "../../components/ContentManagement/OrganisationSelectorModal"

const ChallengeWeekEditorPage = () => {

//#region Initilizations
    const breadcrumbsData = [
        { name: 'Content List', path: `${DASHBOARD_ROOT}/content-management/` },
        { name: 'Edit Challenge of the Week', path: `${DASHBOARD_ROOT}/content-management/challenge-of-the-week` },
    ];

    const searchParams = new URLSearchParams(window.location.search);
    const preOpenedItem = searchParams.get("selected");

    const [challenge, setChallenge] = useState<ContentOfTheWeekWithOrganisations[]>([]);
    const [challengeFiltered, setChallengeFiltered] = useState<ContentOfTheWeekWithOrganisations[]>([]);
    const [deleteFile] = useMutation(executeDeleteImage);

    const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
    const [tobeAddedData, setTobeAddedData] = useState<DataFormik>();
    const addModalRef = useRef<{ clearImageField: () => void }>(null);

    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);
    const [tobeUpdatedData, setTobeUpdatedData] = useState<DataFormik>();
    const updateModalRef = useRef<{ clearImageField: () => void }>(null);
    
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [toBeDeletedData,setToBeDeletedData] = useState<ContentOfTheWeekWithOrganisations>(null);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const [organisations, setOrganisations] = useState<Organisation[]>([]);
    const [isSetupModalOpen, setIsSetupModalOpen] = useState<boolean>(false); 
    const [selectedOrganisations, setSelectedOrganisations] = useState<string[]>([]);
    const [tobeSetuped, setTobeSetuped] = useState<ContentOfTheWeekWithOrganisations>();
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const [search, setSearch] = useState<string>("");
    const [searchOrg, setSearchOrg] = useState<string>("");
    const [searchStatus, setSearchStatus] = useState<number>(0);
    const [isSearching, setIsSearching] =  useState<boolean>(false);
    let timer = useRef<number | null> (null);

    const [highlightedItem, setHighlightedItem] = useState<string | null>(null);

    const { data, loading, error } = useContentOfTheWeekWithOrganisation(ContentOfTheWeekType.CHALLENGE);

    const { data : organisationData, loading : organisationLoading} = useOrganisation();

    const [addChallengeHook] = useMutation(addContentOfTheWeek,{
        onCompleted : () =>{
            toast.success("Challenge was successfully added");
        },
        onError : () =>{
            toast.error("Something went wrong whilst Adding the Challenge. Please try again or if issue persists, please contact us");
        }
    });

    const [updateChallengeHook] = useMutation(editContentOfTheWeek,{
        onCompleted : () =>{
            toast.success("Challenge was successfully updated");
        },
        onError : () =>{
            toast.error("Something went wrong whilst updating the Challenge. Please try again or if issue persists, please contact us");
        }
    });

    const [removeChallengeHook] = useMutation(removeContentOfTheWeek,{
        onCompleted : () =>{
            toast.success("Challenge was successfully deleted");
        },
        onError : () =>{
            toast.error("Something went wrong whilst deleting the Challenge. Please try again or if issue persists, please contact us");
        }
    });

    function Reloader(){
        setTimeout(()=>{
            window.location.reload();
        },1500)
    }

    useEffect(() =>{
        if(organisationData) {
            setOrganisations(organisationData.organisations);
        }
    },[organisationData]);

    useEffect(() =>{
        if(data) {
            setChallenge(data.content_of_the_weeks_by_type);
            setChallengeFiltered(data.content_of_the_weeks_by_type);
        }
    },[data]);

//#endregion Initilizations

//#region Validation 
const ContentOfTheWeekDateValidator = async () => {
    // const startDate = new Date(formData.startDate)
    // const endDate = new Date(formData.endDate)

    // const isStartDateOverLapped = challenge.find(u => new Date(u.start_date) <= startDate && new Date(u.end_date) >= startDate && formData.id !== u._id);
    // if(isStartDateOverLapped) {
    //     toast.error(`Start date of this challenge overlaps with the dates of "${isStartDateOverLapped.title}"`, );
    //     return false;
    // }

    // const isEndDateOverLapped = challenge.find(u => new Date(u.start_date) <= endDate && new Date(u.end_date) >= endDate && formData.id !== u._id);
    // if(isEndDateOverLapped) {
    //     toast.error(`End date of this challenge overlaps with the dates of "${isEndDateOverLapped.title}"`, );
    //     return false;
    // }

    return true;
}
//#endregion Validation

//#region Add Functions
    const toggleAddModal = () =>{
        setIsAddModalOpen(!isAddModalOpen);
    }

    const handleAdd = () =>{
        setTobeAddedData(
            {
                id : "1",
                image : "",
                title : "",
                description :  "",
                startDate : moment().format("yyyy-MM-DD"),
                endDate : moment().add(7,'d').format("yyyy-MM-DD"),
                image_id : ""
            },
        )
        addModalRef.current?.clearImageField()
        toggleAddModal();
    }

    const submitAdd = async (formData : DataFormik, fileId : string) => {
        return new Promise<boolean>((resolve, reject) => {
            try{
                addChallengeHook({
                    variables : {
                        record : {
                            image : formData.image,
                            title : formData.title,
                            description : formData.description,
                            start_date : moment(new Date(formData.startDate)).format("YYYY-MM-DDTHH:mm:ssZ"),
                            end_date : moment(new Date(formData.endDate)).format("YYYY-MM-DDTHH:mm:ssZ"),
                            type : ContentOfTheWeekType.CHALLENGE,
                            image_id : fileId
                        }
                    },
                })
                .then(async (res) => {
                    if(!res.errors){
                        toggleAddModal();
                        resolve(true);
                        Reloader();
                    }else{
                        reject(false);
                    }
                })
                .catch(()=>{
                    reject(false);
                })
            }catch{

            }
        })
    }

//#endregion Add Functions
//#region Edit Functions
    const toggleUpdateModal = () => {
        setIsUpdateModalOpen(!isUpdateModalOpen);
    }

    const HandleEdit = (item : ContentOfTheWeekWithOrganisations) => {
        setTobeUpdatedData({
            id : item._id,
            image : item.image,
            title : item.title,
            description : item.description,
            startDate : moment(item.start_date).format("yyyy-MM-DD"),
            endDate : moment(item.end_date).format("yyyy-MM-DD"),
            image_id : item.image_id

        })
        updateModalRef.current?.clearImageField();
        setIsUpdateModalOpen(true);
    }

    const submitEdit = async (formData : DataFormik, fileId : string) => {
        return new Promise<boolean>((resolve, reject) => {
            try{
                updateChallengeHook({
                    variables : {
                        _id : tobeUpdatedData.id,
                        record : {
                            image : formData.image,
                            title : formData.title,
                            description : formData.description,
                            start_date : moment(new Date(formData.startDate)).format("YYYY-MM-DDTHH:mm:ssZ"),
                            end_date : moment(new Date(formData.endDate)).format("YYYY-MM-DDTHH:mm:ssZ"),
                            image_id : fileId ? fileId : tobeUpdatedData.image_id
                        }
                    },
                })
                .then(async (res) => {
                    if(!res.errors) {
                        toggleUpdateModal();
                        resolve(true);
                        Reloader();
                    }
                    else {
                        reject(false);
                    }
                })
                .catch(()=>{
                    reject(false);
                })
            }
            catch {
                reject(false);
            }
        })
    }

//#endregion Edit Functions
//#region Delete Functions
    const handleDelete = (item : ContentOfTheWeekWithOrganisations) => {
        setToBeDeletedData(item);
        setIsDeleteModalOpen(true);
    }

    const handleDeleteModalCancel = () => {
        setIsDeleteModalOpen(false);
    }

    const handleDeleteModalConfirm = async () => {
        if(isDeleting){
            return;
        }
        try{
            setIsDeleting(true);
            await removeChallengeHook({
                variables : {
                    _id : toBeDeletedData._id
                }
            })
            .then(async (res) => {
                if(!res.errors) {
                    await deleteFile({
                        variables : {
                            fileId : toBeDeletedData.image_id
                        }
                    })
        
                    setIsDeleteModalOpen(false);
                    setIsDeleting(false);
                    Reloader();
                }
                else {
                    setIsDeleting(false);
                }
            })

            
        }
        catch {
            setIsDeleting(false);
        }
    }

//#endregion Delete Functions
//#region Setup Organisation
    const HandleModalSetup = (item : ContentOfTheWeekWithOrganisations) => {
        ToggleModalSetup();
        setTobeSetuped(item);
        setSelectedOrganisations(item.using_organisation_ids);
    }
    const ToggleModalSetup = () => {
        setIsSetupModalOpen(!isSetupModalOpen);
    }

    const filterChallenge = (searchText : string, searchOrganisation : string, searchStatus : number) => {

        searchText = searchText?.trim().toLowerCase();
        const dateNow = new Date();

        setIsSearching(true);
        if(timer?.current) {
            clearTimeout(timer.current);
            timer.current = null;
        }

        timer.current = window.setTimeout(() => {
            const filtered = challenge.filter( u => 
                (!searchText || u.title.toLowerCase().includes(searchText) || u.description.toLowerCase().includes(searchText)) &&
                (!searchOrganisation || u.using_organisation_ids?.includes(searchOrganisation)) && 
                (
                    searchStatus === 1 ? 
                        new Date(u.end_date) < dateNow
                    :
                    searchStatus === 2 ?
                    new Date(u.start_date) <= dateNow && new Date(u.end_date) >= dateNow
                    :
                    searchStatus === 3 ? 
                    new Date(u.start_date) > dateNow && new Date(u.end_date) > dateNow
                    : true
                )
                
            );
            setChallengeFiltered(filtered);
            setIsSearching(false);
        },1500);
        
        
    }

    const HandleChallengeSearch = (value : string) => {
        filterChallenge(value, searchOrg, searchStatus);
    }

    const HandleOrganisationChange = (value : string) => {
        filterChallenge(search, value, searchStatus);
        setSearchOrg(value);
    }

    const HandleStatusChange = (value : number) => {
        filterChallenge(search, searchOrg, value);
        setSearchStatus(value);
    }

    const HandleClearFilter = () => {
        
        setSearchStatus(0);
        setSearchOrg("value");
        setSearch("")
        filterChallenge("", "", 0);
    }

    const HandleSaveSelectedOrganisation = async (selected : string[]) => {
        setIsSaving(true);
        const isUpdated = await updateChallengeHook({
            variables : {
                _id : tobeSetuped._id,
                record : {
                    using_organisation_ids : selected
                }
            },
        }).then((res) => res.errors ? false : true).catch(()=>false);

        
        if(!isUpdated) {
            setIsSaving(true);
            return;
        }

        ToggleModalSetup();
        Reloader();

    }
//#endregion Setup Organisation    


//#region Highlight of Row
useEffect(() => {
    if(challenge) {
        if(preOpenedItem) {
            const getItem = challenge.find((u : ContentOfTheWeekWithOrganisations) => u._id === preOpenedItem);
            if(getItem) {
                HandleEdit(getItem);
                setHighlightedItem(preOpenedItem);
            }
        }
    }
},[challenge, preOpenedItem]);
//#endregion Highlight
    return (
        <>
            <Breadcrumbs 
                crumbs={breadcrumbsData}
            />
            {
                error &&
                <Alert color="danger">
                    Oops, we’re having trouble getting the topics. Please give it a minute, and if it doesn’t resolve, let us know.
                </Alert>
            }
            
            <div className='d-flex justify-content-between'>
                <div>
                    <span className="mr-2 text-danger"><i className="far fa-calendar-times "></i> Past - </span>
                    <span className="mr-2 text-success"><i className="far fa-calendar-check"></i> Present - </span>
                    <span className="mr-2 text-primary"><i className="fa fa-hourglass-half"></i> Future</span>
                </div>
                <Button color="primary mb-2" onClick={handleAdd}>Add Challenge</Button>
            </div>
            <Row className="mb-3">
                <Col className="d-flex">
                    <label className="mt-2 mr-2">Search: </label>
                    <Input className="w-100" type="text" value={search} onChange={(e) => { setSearch(e.currentTarget.value); }} onKeyUp={(e) => { HandleChallengeSearch(e.currentTarget.value); }} placeholder="Search Title here" />
                </Col>
                <Col className="d-flex">
                    <label className="mt-2 mr-2">Organisation: </label>
                    <Input className="w-100" type="select" disabled={organisationLoading} onChange={(e) => ( HandleOrganisationChange(e.currentTarget.value) )} value={searchOrg}>
                        <option value="">All Organisation</option>
                        {
                            organisations.map((item, index) => 
                                <option value={item._id} key={index}>{item.name}</option>
                            )
                        }
                    </Input>
                </Col>
                <Col className="d-flex">
                    <label className="mt-2 mr-2">Status: </label>
                    <Input className="w-100" type="select" placeholder="Search title here" value={searchStatus} onChange={(e) => ( HandleStatusChange(Number(e.currentTarget.value)) )}  >
                        <option value={0} >All</option>
                        <option value={1} >Past</option>
                        <option value={2} >Present </option>
                        <option value={3} >Future </option>
                    </Input>
                    <Button color="secondary" className="ml-3" onClick={HandleClearFilter}>Clear</Button>
                </Col>
            </Row>
            <div className="table-responsive table-max-height" >
                <ContentWeekTable 
                    contents={challengeFiltered}
                    handleDelete={handleDelete}
                    handleEdit={HandleEdit}
                    handleSetup={HandleModalSetup}
                    isLoading={loading || isSearching}
                    highlightedRow={highlightedItem}
                />
            </div>

            <ContentWeekEditorModal
                title="Add Challenge"
                ref={addModalRef}
                dataFormik={tobeAddedData}
                modalOpen={isAddModalOpen}
                toggleModal={toggleAddModal}
                buttonName="Add"
                onSubmit={submitAdd}
                validationBeforeSubmit={ContentOfTheWeekDateValidator}
            />

            <ContentWeekEditorModal
                title="Edit Challenge"
                ref={updateModalRef}
                dataFormik={tobeUpdatedData}
                modalOpen={isUpdateModalOpen}
                toggleModal={toggleUpdateModal}
                buttonName="Edit"
                onSubmit={submitEdit}
                validationBeforeSubmit={ContentOfTheWeekDateValidator}
            />

            <OrganisationSelectorModal
                toggleModal={ToggleModalSetup}
                modalOpen={isSetupModalOpen}
                handleSave={HandleSaveSelectedOrganisation}
                organisations={organisations}
                isButtonDisabled={isSaving}
                selectedOrgs={selectedOrganisations}
            
            />

            <PromptDialog
                isOpen={isDeleteModalOpen}
                onCancel={handleDeleteModalCancel}
                onConfirm={handleDeleteModalConfirm}
                title="Delete Challenge"
                promptMessage="Are you sure you want to delete this Challenge?"
                confirmBtnTxt="Delete"
                isOnProcess={isDeleting}
            />               
           
        </>
    )

}

export { ChallengeWeekEditorPage as ChallengeWeekEditor };