import { Table, Button} from 'reactstrap';
import moment from 'moment';
import { ContentOfTheWeekWithOrganisations } from '../../interfaces/models/ContentOfTheWeek'
import Loading from '../Loading';
import { getImageSmallUrl } from "../../helper/getImageUrl";
import {DateFormat} from "../../helper/constants"
import { useRef, useEffect } from 'react';

interface Props {
    contents : ContentOfTheWeekWithOrganisations[],
    handleDelete : (item : ContentOfTheWeekWithOrganisations) => void; 
    handleEdit : (item : ContentOfTheWeekWithOrganisations) => void; 
    handleSetup : (item : ContentOfTheWeekWithOrganisations) => void; 
    isLoading : boolean;
    highlightedRow? : string;
}

export default function ContentWeekTable({contents, handleDelete , handleEdit, handleSetup, isLoading, highlightedRow} : Props){
    
    const maxLength = 100;
    const currentDate = new Date();
    
    const targetRowRef = useRef(null);

    useEffect(() => {

        if(highlightedRow && targetRowRef) {
            targetRowRef.current.scrollIntoView({
                behavior: 'smooth', // Smooth scrolling
                block: 'nearest',   // Align the row to the nearest edge
            });
        }

    },[highlightedRow]);

    return (
        <Table striped bordered>
            <thead>
                <tr>
                    <th style={{width : '20%'}} >Image</th>
                    <th style={{width : '10%', maxWidth : '10%'}}>Title & Description</th>
                    <th style={{width : '30%', maxWidth : '30%'}}>Organisations</th>
                    <th >Date</th>
                    <th >Action</th>
                </tr>
            </thead>
            <tbody>
                {contents.length === 0 && !isLoading && 
                    <tr>
                        <td colSpan={5} className="text-center">No Data Found</td>
                    </tr>
                }
                {isLoading && 
                    <tr>
                        <td colSpan={5} className="text-center">
                            <Loading />
                        </td>
                    </tr>
                }
                {
                    !isLoading && contents.map((item , index ) =>
                        {
                            return (
                                <tr key={index} 
                                    className={ item._id === highlightedRow ? "table-bg-highlighted" : "" }
                                    ref={ item._id === highlightedRow ? targetRowRef : null} 
                                >
                                    <td>
                                        <img
                                            style={{
                                                borderRadius: "0.375rem",
                                                marginBottom: "1rem",
                                                height : "100px",
                                                width : "150px",
                                                objectFit :"cover"
                                            }}
                                            src={getImageSmallUrl(item.image_id)}
                                            alt={item.title}
                                        />
                                    </td>
                                    <td>
                                        <div className="font-weight-bold">{item.title}</div>
                                        <div style={{ width : 250, maxWidth : 250, 
                                            wordWrap :"break-word", overflowWrap :"break-word", 
                                            whiteSpace: "pre-wrap" }}>
                                            { item.description.length > maxLength ? 
                                            item.description.slice(0, maxLength) + " ..."
                                            : item.description }
                                        </div>
                                    </td>
                                    <td >
                                        <div className="text-capitalize" 
                                            style={{ width : 150, maxWidth : 150, 
                                                wordWrap :"break-word", overflowWrap :"break-word", 
                                                whiteSpace: "pre-wrap" }}
                                        >{item.using_organisations.map(u => u.name).join(", ")}</div>
                                    </td>
                                    <td>
                                        {
                                            new Date(item.end_date) < currentDate 
                                            ? <span title="Date already passed" className=" text-danger"><i className="far fa-calendar-times fa-lg"></i> {moment(item.start_date).format(DateFormat)} to {moment(item.end_date).format(DateFormat)}</span>
                                            : new Date(item.start_date) <= currentDate && new Date(item.end_date) >= currentDate 
                                            ? <span title="Currently Showing for the week" className=" text-success"><i className="far fa-calendar-check fa-lg"></i> {moment(item.start_date).format(DateFormat)} to {moment(item.end_date).format(DateFormat)}</span>
                                            : <span title="Set for the Future Dates" className=" text-primary"><i className="fas fa-hourglass-half"></i> {moment(item.start_date).format(DateFormat)} to {moment(item.end_date).format(DateFormat)}</span>
                                        } 
                                    </td>
                                    <td>
                                        <Button color="primary" size='sm'onClick={()=>{ handleEdit(item) }} >Edit</Button>
                                        <Button color="primary" size='sm' onClick={()=>{ handleSetup(item) }} > Organisations </Button>
                                        <Button color="danger" size='sm' onClick={()=>{ handleDelete(item) }} >Delete</Button>
                                    </td>
                                </tr>
                            )
                        }
                    )
                }
                
            </tbody>
        </Table>
    )

}

