import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import React, { useEffect, useState, Suspense  } from 'react';
import { DASHBOARD_ROOT } from "../../components/Routes/routingConstants";
import UserActivityMonitor from "../../components/AdvanceReporting/UserActivityMonitor"
import UserMetricsPieChart from "../../components/AdvanceReporting/UserMetricsPieChart"
import UserStatusMetricsPieChart from "../../components/AdvanceReporting/UserStatusMetricsPieChart"
import UserNudgeLineChartDailyBased from "../../components/AdvanceReporting/UserNudgeLineChartDailyBased"
import UserUsageLineChartDailyBased from "../../components/AdvanceReporting/UserUsageLineChartDailyBased"
import { Col, Row,Card, CardBody, CardHeader, Button, Modal, ModalBody, ModalFooter, ModalHeader, Form, FormGroup, Label, Input} from "reactstrap"
import { LineGraphType}  from "../../helper/constants"
import FormError from '../../components/Shared/Forms/FormError';
import { useOrganisation } from "../../hooks/useOrganisation";
import { Organisation } from "../../interfaces/models/Organisation";
import { useLocation, useHistory } from 'react-router-dom';
const UserNudgeLineChartMonthlyBased = React.lazy( () => import("../../components/AdvanceReporting/UserNudgeLineChartMonthlyBased"));
const UserUsageLineChartMonthlyBased = React.lazy( () => import("../../components/AdvanceReporting/UserUsageLineChartMonthlyBased"));

const ReportingDashboardPage = () => {
//#region Initialization
    const breadcrumbsData = [
        { name: 'Reports', path: `${DASHBOARD_ROOT}/reporting/dashboard` },
    ];

    const location = useLocation();
    const history = useHistory();
    const searchParams = new URLSearchParams(location.search);

    const organisation = searchParams.get("organisation");

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedOrganisation, setSelectedOrganisation] = useState<string>("");
    const [queryOrganisation] = useState<string>(organisation || "");
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const [selectedLineGraph, setSelectedLineGraph] = useState<number>(LineGraphType.DAILY);
    const [selectedLineUsageGraph, setSelectedLineUsageGraph] = useState<number>(LineGraphType.DAILY);

    const [organisations, setOrganisations] = useState<Organisation[]>([]);
    
    const { data : organisationData, loading : organisationLoading, error : organisationError } = useOrganisation();

    useEffect(() => {
        if(organisationData) { 
            setOrganisations(organisationData.organisations);
        }
    },[organisationData]);
//#endregion Initialization

//#region Actions
    const HandleChangeNudgeGraphType = (type : number) => {
        setSelectedLineGraph(type);
    }

    const HandleChangeUsageGraphType = (type : number) => {
        setSelectedLineUsageGraph(type);
    }

    const ToggleModal = () => {
        setIsModalOpen(!isModalOpen);
    }
    const HandleOpenModal = () => {
        setSelectedOrganisation(queryOrganisation || "");
        ToggleModal();
    }
    const HandleFilterSubmit = () => {
        if(isSubmitting) return;  

        setIsSubmitting(true);
        searchParams.set('organisation', selectedOrganisation); // Add or update the parameter
        history.push(`${location.pathname}?${searchParams.toString()}`); // Push updated URL
        window.location.reload();
    }

    const RedirectToExport = () => {
        history.push(`${DASHBOARD_ROOT}/reporting/dashboard/print?organisation=${selectedOrganisation}`);
    }
    
    const GraphSelectorElement = (stateValue : number, changeHandler : (value : number) => void) => {
        return (
            <Row className="pt-2 pb-2">
                <Col>
                    <Card
                        className={`cursor-pointer text-center shadow` + (stateValue === LineGraphType.DAILY ? " border-primary text-primary" :"")}
                        onClick={()=>{changeHandler(LineGraphType.DAILY)}}
                    >
                        <CardBody className="pt-2 pb-2" >
                            <i className="fas fa-calendar-day"></i> <b>Daily</b>
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card
                        className={`cursor-pointer text-center shadow` + (stateValue === LineGraphType.MONTHLY ? " border-primary text-primary" :"")}
                        onClick={()=>{changeHandler(LineGraphType.MONTHLY)}}
                    >
                        <CardBody className="pt-2 pb-2" >
                            <i className="fas fa-calendar-alt"></i> <b>Monthly</b>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }
//#endregion Actions

    return (
        <>
            <Breadcrumbs 
                crumbs={breadcrumbsData}
            />
            <Row className="pb-2">
                <Col md={9}>
                    <h4 className="pt-2">Organisation : "{ !organisationLoading ? ( organisations.find(u => u._id === queryOrganisation)?.name || "All") :"-"}"</h4>
                </Col>
                <Col className="d-flex justify-content-end" md={3}>
                    <Button onClick={HandleOpenModal} color="primary" className="mr-1">Filter</Button>
                    <Button  color="primary" className="mr-1" onClick={RedirectToExport}>Export</Button>
                </Col>
            </Row>
            <Card>
                <CardBody>
                    <UserActivityMonitor
                        organisation={queryOrganisation}
                    />
                </CardBody>
            </Card>
            <Row>
                <Col>
                    <Card className="mt-2">
                        <CardHeader>
                            <Row >
                                <Col>
                                    <h4 className="mb-0">User Status Breakdown</h4>
                                </Col>
                                <Col className="d-flex justify-content-end">
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <UserStatusMetricsPieChart
                                organisation_id={queryOrganisation}
                            />
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card className="mt-2">
                        <CardHeader>
                            <Row >
                                <Col >
                                    <h4 className="mb-0">User Activity Breakdown</h4>
                                </Col>
                                <Col className="d-flex justify-content-end">
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <UserMetricsPieChart
                                organisation_id={queryOrganisation}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            
            <Card className="mt-2">
                <CardHeader className="pb-0">
                    <Row className="mb-3">
                        <Col >
                            <h4 className="mb-0">User Nudge Report</h4>
                        </Col>
                        <Col className="d-flex justify-content-end">
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                {
                    GraphSelectorElement(selectedLineGraph,HandleChangeNudgeGraphType)
                }
                {
                    selectedLineGraph === LineGraphType.DAILY ?
                    <UserNudgeLineChartDailyBased 
                        organisation_id={queryOrganisation}
                    />
                    : 
                    <Suspense  fallback={<div style={{height: '400px' }}>Loading...</div>}>
                        <UserNudgeLineChartMonthlyBased 
                            organisation_id={queryOrganisation}
                        /> 
                    </Suspense>
                }
                </CardBody>
            </Card>

            <Card className="mt-2">
                <CardHeader className="pb-0">
                    <Row className="mb-3">
                        <Col >
                            <h4 className="mb-0">User Usage Report</h4>
                        </Col>
                        <Col className="d-flex justify-content-end">
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                {
                    GraphSelectorElement(selectedLineUsageGraph,HandleChangeUsageGraphType)
                }
                {
                    selectedLineUsageGraph === LineGraphType.DAILY ?
                    <UserUsageLineChartDailyBased 
                        organisation_id={queryOrganisation}
                    />
                    : 
                    <Suspense fallback={<div style={{height: '400px' }}>Loading...</div>}>
                        <UserUsageLineChartMonthlyBased
                            organisation_id={queryOrganisation}
                        /> 
                    </Suspense>
                }
                </CardBody>
            </Card>

            <Modal
                id="reportingDashboard-filter-modal"
                backdrop={true}
                isOpen={isModalOpen}>
                <ModalHeader toggle={ToggleModal} className="pb-0">
                    <p className="text-lg">Filter</p>
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label>Organisation *</Label>
                            <Input
                                onChange={(e) => { setSelectedOrganisation(e.target.value); }}
                                value={selectedOrganisation}
                                type="select"
                                disabled={organisationLoading}
                            >
                                <option value="">All</option>
                                {
                                    organisations.map((item, index) => (
                                        <option key={index} value={item._id}>{item.name}</option>
                                    ))
                                }
                            </Input>
                            {
                                organisationError && 
                                <FormError>Error on Getting Organisations</FormError>
                            }
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" disabled={isSubmitting} onClick={ToggleModal} >Close </Button>
                    <Button color="primary" disabled={isSubmitting} onClick={HandleFilterSubmit} >Filter</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}


export { ReportingDashboardPage as ReportingDashboard };